import Vue from 'vue'
import App from './App.vue'
import i18n from './locales'
import router from './router'
import store from './store'
import AuthService from './services/AuthService'
import auth from './plugins/Auth'
import vueDebounce from 'vue-debounce'
import VueCurrencyInput from 'vue-currency-input'
import Toasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import axios from 'axios'

// Currently a TS error if importing v-tooltip, so we need to use require.
// Check for more info: https://github.com/Akryum/v-tooltip/pull/464
/* eslint-disable @typescript-eslint/no-var-requires */
const VTooltip = require('v-tooltip')

Vue.config.productionTip = false

Vue.use(vueDebounce)
Vue.use(VueCurrencyInput)
Vue.use(Vuelidate)
Vue.use(VTooltip, {
  defaultClass: 'tooltip',
  defaultOffset: '10px'
})
Vue.use(Toasted, {
  keepOnHover: true,
  fullWidth: true,
  fitToScreen: true,
  type: 'error', // 'success', 'info', 'error'
  singleton: true,
  duration: 6000
})

const authService: AuthService = new AuthService()

Vue.use(auth, authService)

async function mountVue() {
  // Make axios use our access token for each request
  // Only works with the global instance of axios though. The ones
  // created with axios.create() won't have the authorization header set
  axios.interceptors.request.use(async function(config) {
    config.headers.Authorization = await authService.getAccessToken()
    return config
  })

  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (router.app.$auth.user.profile.roles.indexOf('ROLE_ADMIN') !== -1) {
        next()
      } else {
        next({
          path: '/'
        })
      }
    } else if (to.matched.some(record => record.meta.requiresTeach)) {
      if (
        router.app.$auth.user.profile.roles.indexOf('ROLE_TEACHER') !== -1 ||
        router.app.$auth.user.profile.roles.indexOf('ROLE_ADMIN') !== -1
      ) {
        next()
      } else {
        next({
          path: '/videos'
        })
      }
    } else {
      next() // make sure to always call next()!
    }
  })

  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

async function initializeAuthContext() {
  if (
    window.location.search.includes('code=') &&
    window.location.search.includes('state=')
  ) {
    await authService.handleCallback()
    window.history.replaceState(
      {},
      window.document.title,
      window.location.origin + window.location.pathname
    )
    mountVue()
  } else {
    const user = await authService.getUser()
    if (user !== null) {
      mountVue()
    } else {
      authService.login()
    }
  }
}

initializeAuthContext()
