






























import { Component, Vue } from 'vue-property-decorator'
import { catalogRoutes } from '@/constants/routes'
import RoundIcon from '@/components/RoundIcon.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'

const Extender = Vue.extend({
  components: {
    RoundIcon,
    BPrimary
  }
})

@Component
export default class SAndLCreatedAddChapters extends Extender {
  catalogRoutes = catalogRoutes
}
