
















import { Component, Vue } from 'vue-property-decorator'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

const Extender = Vue.extend({
  components: {
    vue2Dropzone
  }
})

@Component
export default class CoverUploader extends Extender {
  dropzoneOptions = {
    url: '/',
    thumbnailWidth: 150,
    maxFilesize: 10,
    maxFiles: 1,
    acceptedFiles: 'image/*',
    autoProcessQueue: false,
    autoQueue: true,
    addRemoveLinks: true,
    dictDefaultMessage: '',
    headers: { Authorization: '' }
  }

  created() {
    this.dropzoneOptions.dictDefaultMessage = this.$t('upload_image') as string
    this.dropzoneOptions.headers.Authorization = this.$auth.user.access_token
  }

  fileAdded() {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const dropzone: any = this.$refs.coverUploader

    const queuedFiles = dropzone.getQueuedFiles()

    if (queuedFiles.length > 0) {
      dropzone.removeFile(queuedFiles[0])
    }

    this.$emit('imgAdded')
  }
}
