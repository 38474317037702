

















































import { Component, Vue, Watch } from 'vue-property-decorator'

const Props = Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: [String, Object],
      required: false,
      default: ''
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false
    },
    keyToShow: {
      type: String,
      required: true
    },
    keyToBind: {
      type: String,
      required: true
    },
    error: {
      type: Object,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

@Component
export default class Dropdown extends Props {
  mouseOverDropdown = false
  showDropdown = false
  input = {}
  formattedItems = []

  created() {
    this.setItems()
    this.prefill()
  }

  openDropdown() {
    if (!this.readonly) {
      this.showDropdown = true
    }
  }

  onInputBlur() {
    if (!this.mouseOverDropdown) {
      this.showDropdown = false
    }
  }

  onItemClick(item: string) {
    this.$emit('input', item[this.keyToBind])
    this.input = item
    this.mouseOverDropdown = false
    this.showDropdown = false
  }

  setItems() {
    if (this.items.length === 1) {
      const firstItem = this.items[0] as object
      this.input = firstItem
      this.$emit('input', firstItem[this.keyToBind])
    } else {
      this.input = ''
    }

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    this.formattedItems = this.items as any
  }

  prefill() {
    if (this.value) {
      this.input = this.items.find(
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (item: any) => item[this.keyToBind] === this.value
      ) as object
    }
  }

  @Watch('items')
  onItemsChange() {
    this.setItems()
  }

  @Watch('value')
  onValueChange() {
    this.prefill()
  }
}
