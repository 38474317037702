




















































import { Vue, Component } from 'vue-property-decorator'
import { adminRoutes } from '@/constants/routes'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import PageInfo from '@/components/pages/PageInfo.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import Resource from '@/components/admin/Resource.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader,
    PageInfo,
    BPrimary,
    Resource
  }
})

@Component
export default class Resources extends Extender {
  adminRoutes = adminRoutes

  created() {
    this.$store.dispatch('Resources/clearSelectedResource')

    api.Resource.getAll().then(res => {
      this.$store.dispatch('Resources/setResources', res)
    })
  }

  get resources() {
    return this.$store.state.Resources.resources
  }
}
