import Configurations from '@/views/my-s-and-l/Configurations.vue'
import ConfigurationDetail from '@/views/my-s-and-l/ConfigurationDetail.vue'
import ConfigurationInformation from '@/views/my-s-and-l/ConfigurationInformation.vue'
import ConfigurationChapters from '@/views/my-s-and-l/ConfigurationChapters.vue'
import ConfigurationSpecifications from '@/views/my-s-and-l/ConfigurationSpecifications.vue'
import ConfigurationOrder from '@/views/my-s-and-l/ConfigurationOrder.vue'
import CreateConfiguration from '@/views/my-s-and-l/CreateConfiguration.vue'
import MyOrders from '@/views/my-s-and-l/MyOrders.vue'
import { mySAndLRoutes } from '@/constants/routes'

export const mySAndL = [
  {
    path: '/configurations',
    component: Configurations,
    name: mySAndLRoutes.myConfigurations,
    meta: {
      requiresTeach: true,
      composer: {
        alwaysShow: false,
        onlyWhenSelection: true
      }
    }
  },
  {
    path: '/configurations/create',
    component: CreateConfiguration,
    name: mySAndLRoutes.createConfiguration,
    meta: {
      requiresTeach: true
    }
  },
  {
    path: '/configurations/:configurationId',
    component: ConfigurationDetail,
    children: [
      {
        path: '',
        name: mySAndLRoutes.configurationInformation,
        component: ConfigurationInformation,
        meta: {
          requiresTeach: true,
          composer: {
            alwaysShow: false,
            onlyWhenSelection: true
          }
        }
      },
      {
        path: 'chapters',
        name: mySAndLRoutes.configurationChapters,
        component: ConfigurationChapters,
        meta: {
          requiresTeach: true,
          composer: {
            alwaysShow: false,
            onlyWhenSelection: true
          }
        }
      },
      {
        path: 'specifications',
        name: mySAndLRoutes.configurationSpecifications,
        component: ConfigurationSpecifications,
        meta: {
          requiresTeach: true,
          composer: {
            alwaysShow: false,
            onlyWhenSelection: true
          }
        }
      },
      {
        path: 'order',
        name: mySAndLRoutes.configurationOrder,
        component: ConfigurationOrder,
        meta: {
          requiresTeach: true,
          composer: {
            alwaysShow: false,
            onlyWhenSelection: true
          }
        }
      }
    ]
  },
  {
    path: '/orders',
    component: MyOrders,
    name: mySAndLRoutes.myOrders,
    meta: { requiresTeach: true }
  }
]
