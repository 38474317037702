


















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { IConfiguration, IInstitute } from '@/interfaces'
import { mySAndLRoutes, catalogRoutes } from '@/constants/routes'
import { authorsToArr } from '@/functions/authors'
import { required } from 'vuelidate/lib/validators'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import Dropdown from '@/components/inputs/Dropdown.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader,
    LabelOutInput,
    BPrimary,
    Dropdown
  },
  validations: {
    formData: {
      title: { required },
      institute: { required },
      department: { required },
      fieldOfStudy: { required },
      academicYear: { required },
      phase: { required }
    }
  }
})

@Component
export default class CreateConfiguration extends Extender {
  mySAndLRoutes = mySAndLRoutes
  coauthors = ''

  institutes = []
  campuses = []

  loading = false

  formData: IConfiguration = {
    id: '',
    title: '',
    author: '',
    authorText: '',
    coauthors: [],
    subTitle: '',
    coverImage: '',
    institute: '',
    campus: '',
    department: '',
    academicYear: '',
    fieldOfStudy: '',
    previewFileUrl: '',
    status: 'IN_DRAFT',
    phase: '',
    orderedAt: '',
    components: [],
    sellingPrice: 0,
    isComponentInfoOkay: false,
    isSpecInfoOkay: false,
    numberOfPages: 0
  }

  created() {
    api.Institute.get().then(res => (this.institutes = res.data))
  }

  async onFormSubmit() {
    this.$v.$touch()

    if (this.$v.$invalid) return

    this.loading = true
    this.formData.coauthors = authorsToArr(this.coauthors)

    const chaptersLength = this.selectedChapters.length
    const configurationsLength = this.configurations.length

    api.Configuration.post(this.formData).then(res => {
      const id = res.data.id

      api.Configuration.getAll()
        .then(res => {
          this.$store.dispatch('Configurations/setConfigurations', res.data)

          if (chaptersLength > 0 && configurationsLength === 0) {
            this.$router.replace({
              name: catalogRoutes.catalogSelection,
              params: {
                configurationId: id
              }
            })
          } else {
            this.$router.replace({
              name: mySAndLRoutes.configurationChapters,
              params: {
                configurationId: id
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    })
  }

  get configurations() {
    return this.$store.state.Configurations.configurations
  }

  get selectedChapters() {
    return this.$store.state.NewConfiguration.selectedChapters
  }

  @Watch('formData.institute', { deep: true })
  onInstituteChange() {
    this.campuses = []
    this.formData.campus = ''

    /* eslint-disable-next-line */
    const institute: any = this.institutes.find(
      (institute: IInstitute) => institute.id === this.formData.institute
    )

    if (institute) {
      this.campuses = institute.campuses
    }
  }
}
