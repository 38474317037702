
























import { Component, Vue } from 'vue-property-decorator'
import { adminRoutes } from '@/constants/routes'
import ConfirmButtons from '@/components/buttons/ConfirmButtons.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    ConfirmButtons
  }
})

@Component
export default class DeleteResourceConfirm extends Extender {
  error = ''

  onDelete() {
    api.Resource.delete(this.resource.id)
      .then(() => {
        this.$store.dispatch('Modals/setModal', null)

        if (this.$route.name === adminRoutes.resourceInformation) {
          this.$store.dispatch('Modals/setModal', null)
          this.$router.replace({ name: adminRoutes.resourcesOverview })
        }

        if (this.$route.name === adminRoutes.resourcesOverview) {
          api.Resource.getAll().then(res => {
            this.$store.dispatch('Resources/setResources', res)
            this.$store.dispatch('Modals/setModal', null)
          })
        }
      })
      .catch(err => (this.error = err.response.data.message))
  }

  get resource() {
    return this.$store.state.Resources.selectedResource
  }
}
