




































































import { Component, Vue } from 'vue-property-decorator'
import FormWithCover from '@/components/FormWithCover.vue'
import SmallInfo from '@/components/text-elements/SmallInfo.vue'
import ImagePlaceholder from '@/components/ImagePlaceholder.vue'

const Extender = Vue.extend({
  components: {
    FormWithCover,
    SmallInfo,
    ImagePlaceholder
  }
})

@Component
export default class Information extends Extender {
  get selectedResource() {
    return this.$store.state.Resources.selectedResource
  }
}
