




















































import { Component, Vue } from 'vue-property-decorator'
import { EModal } from '@/enums'
import PDFPreview from '@/components/modals/PDFPreview.vue'
import VideoModal from '@/components/modals/VideoModal.vue'
import UserRegistered from '@/components/modals/UserRegistered.vue'
import OrderSAndLConfirm from '@/components/modals/OrderSAndLConfirm.vue'
import AddOwnContentToSAndL from '@/components/modals/AddOwnContentToSAndL.vue'
import CatalogSelectionFail from '@/components/modals/CatalogSelectionFail.vue'
import DeleteResourceConfirm from '@/components/modals/DeleteResourceConfirm.vue'
import PublishResourceConfirm from '@/components/modals/PublishResourceConfirm.vue'
import CatalogSelectionSuccess from '@/components/modals/CatalogSelectionSuccess.vue'
import DeleteConfigurationConfirm from '@/components/modals/DeleteConfigurationConfirm.vue'
import DeleteResourceChapterConfirm from '@/components/modals/DeleteResourceChapterConfirm.vue'
import DeleteConfigurationComponentConfirm from '@/components/modals/DeleteConfigurationComponentConfirm.vue'
import ReviseResourceConfirm from '@/components/modals/ReviseResourceConfirm.vue'

const Extender = Vue.extend({
  components: {
    PDFPreview,
    VideoModal,
    UserRegistered,
    OrderSAndLConfirm,
    AddOwnContentToSAndL,
    CatalogSelectionFail,
    DeleteResourceConfirm,
    PublishResourceConfirm,
    CatalogSelectionSuccess,
    DeleteConfigurationConfirm,
    DeleteResourceChapterConfirm,
    DeleteConfigurationComponentConfirm,
    ReviseResourceConfirm
  }
})

@Component
export default class Modal extends Extender {
  EModal = EModal

  closeModal() {
    if (this.closable) {
      this.$store.dispatch('Modals/setModal', null)
    }
  }

  get modalType() {
    return this.$store.state.Modals.modal.type
  }

  get closable() {
    return this.$store.state.Modals.modal.closable
  }
}
