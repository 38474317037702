
















import { Component, Vue } from 'vue-property-decorator'
import BIcon from '@/components/buttons/BIcon.vue'

const Extender = Vue.extend({
  components: {
    BIcon
  },
  props: {
    movable: {
      type: Boolean,
      required: false,
      default: true
    }
  }
})

@Component
export default class ChapterMoveItem extends Extender {}
