
































import { Component, Vue, Watch } from 'vue-property-decorator'

const Extender = Vue.extend({
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    error: {
      type: Object,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'input',
      validator: val => ['input', 'textarea'].indexOf(val) !== -1
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

@Component
export default class LabelOutInput extends Extender {
  input: string | number = ''

  created() {
    this.input = this.value
  }

  onChange(value: string) {
    this.$emit('input', value)
  }

  @Watch('value')
  watchValueChange() {
    this.input = this.value
  }
}
