





















import { Component, Vue } from 'vue-property-decorator'
import { IFilter } from '@/interfaces'

const Extender = Vue.extend({})

@Component
export default class SearchFilter extends Extender {
  selectedFilterValue = 'ALL'

  get formattedSelectedFilter() {
    return this.filters.find(
      (item: IFilter) => item.value === this.selectedFilterValue
    ) as IFilter
  }

  get filters() {
    return this.$store.state.Search.filters
  }

  onChange() {
    this.$store.dispatch('Search/setSelectedFilter', this.selectedFilterValue)
    this.$emit('change')
  }
}
