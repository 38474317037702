




















import { Component, Vue } from 'vue-property-decorator'
import getVideoId from 'get-video-id'

@Component
export default class VideoModal extends Vue {
  closeModal() {
    this.$store.dispatch('Videos/setSelectedVideo', null)
    this.$store.dispatch('Modals/setModal', null)
  }

  get videoId() {
    return getVideoId(this.selectedVideo.url).id
  }

  get selectedVideo() {
    return this.$store.state.Videos.selectedVideo
  }
}
