
















import { Component, Vue } from 'vue-property-decorator'
import { IChapter, IModal } from '@/interfaces'
import { EModal } from '@/enums'
import BIcon from '@/components/buttons/BIcon.vue'

const Extender = Vue.extend({
  components: {
    BIcon
  },
  props: {
    chapter: {
      type: Object as () => IChapter,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  }
})

@Component
export default class ChapterSelect extends Extender {
  openPreviewModal() {
    const modal: IModal = {
      type: EModal.PREVIEW_MODAL,
      edit: false,
      closable: true,
      pdfUrl: this.chapter.previewFileUrl,
      title: this.chapter.title
    }

    this.$store.dispatch('Modals/setModal', modal)
  }
}
