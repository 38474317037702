
















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { IResource, IModal } from '@/interfaces'
import { authorsToArr } from '@/functions/authors'
import { EModal } from '@/enums'
import { required, minLength, minValue } from 'vuelidate/lib/validators'
import axios from 'axios'
import CoverUploader from '@/components/CoverUploader.vue'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import Currency from '@/components/inputs/Currency.vue'
import FormWithCover from '@/components/FormWithCover.vue'
import Tags from '@/components/inputs/Tags.vue'
import Dropdown from '@/components/inputs/Dropdown.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import BIcon from '@/components/buttons/BIcon.vue'
import ImagePlaceholder from '@/components/ImagePlaceholder.vue'
import InfoBox from '@/components/InfoBox.vue'
import PDFUpload from '@/components/PDFUpload.vue'
import RoundIcon from '@/components/RoundIcon.vue'
import cloneDeep from 'lodash.clonedeep'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    CoverUploader,
    LabelOutInput,
    Currency,
    FormWithCover,
    Tags,
    Dropdown,
    BPrimary,
    BIcon,
    ImagePlaceholder,
    InfoBox,
    PDFUpload,
    RoundIcon
  },
  validations: {
    authors: { required },
    formData: {
      title: { required },
      description: { required },
      isbn: { required },
      sellingPrice: { required, minValue: minValue(1) },
      keywords: { required, minLength: minLength(1) },
      subjectArea: { required },
      publisher: { required }
    }
  }
})

@Component
export default class ResourceInformation extends Extender {
  formData: IResource | null = null
  authors = ''
  loading = true
  updating = false
  colophonUrl = ''

  $refs!: {
    dropzoneParent: HTMLFormElement
  }

  created() {
    axios
      .all([
        api.SubjectArea.getAll(),
        api.Publisher.getAll(),
        api.Resource.get(this.$route.params.resourceId)
      ])
      .then(
        axios.spread((...res) => {
          this.$store.dispatch('Resources/setSubjectAreas', res[0].data)
          this.$store.dispatch('Resources/setPublishers', res[1].data)
          this.$store.dispatch('Resources/setSelectedResource', res[2].data)
          this.formData = cloneDeep(res[2].data)
          this.authors = cloneDeep(res[2].data.authors.join(', '))

          this.colophonUrl = `${process.env.VUE_APP_API_URL}/catalog-resource/${res[2].data.id}/upload-colofon`
        })
      )
      .finally(() => (this.loading = false))
  }

  openColophon() {
    const modal: IModal = {
      type: EModal.PREVIEW_MODAL,
      edit: false,
      closable: false,
      pdfUrl: this.selectedResource.colofonUrl,
      title: this.$t('colophon')
    }
    this.$store.dispatch('Modals/setModal', modal)
  }

  colophonSuccess() {
    api.Resource.get(this.$route.params.resourceId).then(res => {
      this.$store.dispatch('Resources/setSelectedResource', res.data)
      this.formData = cloneDeep(res.data)
      this.authors = cloneDeep(res.data.authors.join(', '))
      this.$toasted.show(this.$t('colophone_updated_successfully') as string, {
        type: 'success'
      })
    })
  }

  onPublish() {
    this.$store.dispatch('Resources/setSelectedResource', this.selectedResource)
    this.$store.dispatch('Modals/setModal', {
      type: EModal.PUBLISH_RESOURCE_CONFIRM,
      edit: false
    })
  }

  onDelete() {
    this.$store.dispatch('Resources/setSelectedResource', this.selectedResource)
    this.$store.dispatch('Modals/setModal', {
      type: EModal.DELETE_RESOURCE_CONFIRM,
      edit: false
    })
  }

  onRevise() {
    this.$store.dispatch('Resources/setSelectedResource', this.selectedResource)
    this.$store.dispatch('Modals/setModal', {
      type: EModal.REVISE_RESOURCE_CONFIRM,
      edit: false
    })
  }

  updateImage() {
    const uploader = this.$refs.dropzoneParent.$refs.coverUploader

    uploader.setOption(
      'url',
      `${process.env.VUE_APP_API_URL}/catalog-resource/${this.selectedResource.id}/upload-cover-image`
    )

    setTimeout(() => {
      uploader.processQueue()
    }, 1)
  }

  onFormSubmit() {
    this.$v.$touch()
    if (this.$v.$invalid) return

    if (this.formData) {
      this.updating = true
      this.formData.authors = authorsToArr(this.authors)
      this.formData.id = ''

      api.Resource.update(this.$route.params.resourceId, this.formData)
        .then(res => {
          api.Resource.get(res.data.id).then(res => {
            this.$store.dispatch('Resources/setSelectedResource', res.data)

            this.$toasted.show(
              this.$i18n.t('resouce_edited_successfully') as string,
              {
                type: 'success'
              }
            )
          })
        })
        .finally(() => {
          this.updating = false
        })
    }
  }

  get selectedResource() {
    return this.$store.state.Resources.selectedResource
  }

  get subjectAreas() {
    return this.$store.state.Resources.subjectAreas
  }

  get publishers() {
    return this.$store.state.Resources.publishers
  }
}
