


























import { Component, Vue } from 'vue-property-decorator'
import BPrimary from '@/components/buttons/BPrimary.vue'

const Extender = Vue.extend({
  components: {
    BPrimary
  }
})

@Component
export default class UserRegistered extends Extender {
  created() {
    localStorage.setItem('REGISTER_MODAL_CHECKED', 'true')
  }
}
