import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IFilter, ISubjectArea } from '@/interfaces'

@Module({ namespaced: true })
export class Search extends VuexModule {
  filters: Array<IFilter> = [
    {
      value: 'TITLE',
      short: 'TITLE',
      long: 'op titel zoeken'
    },
    {
      value: 'AUTHOR',
      short: 'AUTEUR',
      long: 'op auteur zoeken'
    },
    {
      value: 'ISBN',
      short: 'ISBN',
      long: 'op isbn zoeken'
    },
    {
      value: 'TEXT',
      short: 'TITEL & KEYWORD',
      long: 'op titel & zoekwoord zoeken'
    },
    {
      value: 'ALL',
      short: 'ALLES',
      long: 'alle categorieën'
    }
  ]
  selectedFilterValue: string | null = 'ALL'
  activeSearch: string | null = ''
  selectedSubjectAreaId: string | null = null
  sort: string | null = 'TITLE_ASC'
  subjectAreas: Array<ISubjectArea> = []

  /* === MUTATIONS === */
  @Mutation
  SET_SELECTED_FILTER(filter: string | null = null) {
    this.selectedFilterValue = filter
  }
  @Mutation
  SET_ACTIVE_SEARCH(value: string | null = null) {
    this.activeSearch = value
  }
  @Mutation
  SET_SELECTED_SUBJECT_AREA_ID(subjectAreaId: string | null) {
    this.selectedSubjectAreaId = subjectAreaId
  }
  @Mutation
  SET_SELECTED_SORT(sort: string) {
    this.sort = sort
  }
  @Mutation
  SET_SUBJECT_AREAS(subjectAreas: Array<ISubjectArea> | []) {
    this.subjectAreas = subjectAreas
  }
  /* ================= */

  /* === ACTIONS === */
  @Action
  setSelectedFilter(filter: string | null = null) {
    this.context.commit('SET_SELECTED_FILTER', filter)
  }
  @Action
  setActiveSearch(value: string | null = null) {
    this.context.commit('SET_ACTIVE_SEARCH', value)
  }
  @Action
  setSelectedSubjectAreaId(subjectAreaId: string | null) {
    this.context.commit('SET_SELECTED_SUBJECT_AREA_ID', subjectAreaId)
  }
  @Action
  setSelectedSort(sort: string) {
    this.context.commit('SET_SELECTED_SORT', sort)
  }
  @Action
  setSubjectAreas(subjectAreas: Array<ISubjectArea> | []) {
    this.context.commit('SET_SUBJECT_AREAS', subjectAreas)
  }
  /* =============== */
}
