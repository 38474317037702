import Vue from 'vue'
import VueI18n from 'vue-i18n'
import nl from './translations/nl.json'
import en from './translations/en.json'

Vue.use(VueI18n)

export const defaultLocale = 'nl'
const languages = {
  nl,
  en
}

const messages = Object.assign(languages)
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages
})

export default i18n
