


























import VueTagsInput from '@johmun/vue-tags-input'
import { Component, Vue, Watch } from 'vue-property-decorator'

type TTag = {
  text: string
  tiClasses: Array<string>
}

const Extender = Vue.extend({
  components: {
    VueTagsInput
  },
  props: {
    tags: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: true
    },
    error: {
      type: Object,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

@Component
export default class Tags extends Extender {
  keyword = ''
  keywords: Array<TTag> = []

  created() {
    if (this.tags) {
      for (const tag of this.tags) {
        this.keywords.push({
          text: tag as string,
          tiClasses: ['ti-valid']
        })
      }
    }
  }

  mounted() {
    const keywords: HTMLElement | null = document.querySelector('.ti-input')
    const label: HTMLElement | null = document.querySelector('.cap.tags-label')
    const input: HTMLInputElement | null = document.querySelector(
      '.ti-new-tag-input'
    )

    if (keywords && !this.readonly) {
      keywords.addEventListener('click', () => {
        if (input && label) {
          input.focus()
          keywords.style.borderColor = '#236cbc'
          label.style.marginLeft = '0.2rem'
          label.style.color = '#236cbc'
        }
      })

      keywords.addEventListener('focusout', () => {
        keywords.style.borderColor = '#edeff2'

        if (label) {
          label.style.marginLeft = '0'
          label.style.color = '#1b3254'
        }
      })
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  tagsChanged(newTags: any) {
    const lowerCased = newTags.map((tag: TTag) => {
      tag.text = tag.text.toLowerCase()

      return tag
    })

    this.keywords = lowerCased

    this.$emit(
      'newItems',
      lowerCased.map((tag: TTag) => tag.text)
    )
  }

  @Watch('tags')
  watchTagsChange(newVal: Array<TTag>) {
    if (newVal.length === 0) {
      this.keywords = newVal
    }
  }
}
