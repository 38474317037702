var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resource flex align-stretch"},[_c('router-link',{staticClass:"flex align-stretch",attrs:{"to":{
      name: _vm.adminRoutes.resourceInformation,
      params: { resourceId: _vm.resource.id }
    }}},[(_vm.resource.coverImageUrl)?_c('img',{attrs:{"src":_vm.resource.coverImageUrl,"alt":_vm.$t('resource_img_alt')}}):_c('ImagePlaceholder'),_c('div',{staticClass:"content flex direction-col justify-center"},[_c('h4',{staticClass:"header-4"},[_vm._v(_vm._s(_vm.resource.title))]),(_vm.resource.chapters.length === 1)?_c('h3',{staticClass:"text-navy body-2 mt-1"},[_vm._v(" "+_vm._s(_vm.resource.chapters.length)+" "+_vm._s(_vm.$t('chapter'))+" ")]):_c('h3',{staticClass:"text-navy body-2 mt-1"},[_vm._v(" "+_vm._s(_vm.resource.chapters.length)+" "+_vm._s(_vm.$t('chapters'))+" ")]),(_vm.resource.publishedAt !== null)?_c('div',[_c('p',{staticClass:"text-rock copy mt-2"},[_vm._v(" "+_vm._s(_vm.$t('published_at'))+" "+_vm._s(_vm.resource.publishedAt)+" ")])]):_vm._e(),(_vm.resource.publishedAt !== null && _vm.resource.newVersion !== null)?_c('p',{staticClass:"text-tomato copy mt-2"},[_vm._v(" "+_vm._s(_vm.$t('has_revision'))+" ")]):_vm._e(),(
          _vm.resource.publishedAt === null && _vm.resource.previousVersion !== null
        )?_c('p',{staticClass:"text-grass copy mt-2"},[_vm._v(" "+_vm._s(_vm.$t('is_revision'))+" ")]):_vm._e()])],1),_c('footer',{staticClass:"flex center"},[(_vm.resource.publishedAt === null)?_c('div',{staticClass:"flex"},[_c('BIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.$t('publish_resource') }),expression:"{ content: $t('publish_resource') }"}],staticClass:"mr-1",attrs:{"icon":"globe","color":"primary"},nativeOn:{"click":function($event){return _vm.onPublish($event)}}}),_c('BIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.$t('delete_resource'), classes: ['danger'] }),expression:"{ content: $t('delete_resource'), classes: ['danger'] }"}],staticClass:"mr-1",attrs:{"icon":"trash","color":"danger"},nativeOn:{"click":function($event){return _vm.onDelete($event)}}}),_c('BIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('cant_revise_unpublished_resource'),
          classes: ['disabled']
        }),expression:"{\n          content: $t('cant_revise_unpublished_resource'),\n          classes: ['disabled']\n        }"}],attrs:{"icon":"copy","color":"disabled"}})],1):_vm._e(),(_vm.resource.publishedAt !== null)?_c('div',{staticClass:"flex"},[_c('BIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('resource_is_already_published'),
          classes: ['disabled']
        }),expression:"{\n          content: $t('resource_is_already_published'),\n          classes: ['disabled']\n        }"}],staticClass:"mr-1",attrs:{"icon":"globe","color":"disabled"}}),_c('BIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('cant_delete_published_resource'),
          classes: ['disabled']
        }),expression:"{\n          content: $t('cant_delete_published_resource'),\n          classes: ['disabled']\n        }"}],staticClass:"mr-1",attrs:{"icon":"trash","color":"disabled"}}),(_vm.resource.newVersion === null)?_c('BIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.$t('revise_resource'), classes: ['none'] }),expression:"{ content: $t('revise_resource'), classes: ['none'] }"}],attrs:{"icon":"copy","color":"none"},nativeOn:{"click":function($event){return _vm.onRevise($event)}}}):_c('BIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('resource_already_has_revision'),
          classes: ['disabled']
        }),expression:"{\n          content: $t('resource_already_has_revision'),\n          classes: ['disabled']\n        }"}],attrs:{"icon":"copy","color":"disabled"}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }