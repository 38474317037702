import { IOrder } from '@/interfaces'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export class Orders extends VuexModule {
  orders: Array<IOrder> = []

  /* === MUTATIONS === */
  @Mutation
  SET_ORDERS(orders: Array<IOrder>) {
    this.orders = orders
  }
  /* ================= */

  /* === ACTIONS === */
  @Action
  setOrders(orders: Array<IOrder>) {
    this.context.commit('SET_ORDERS', orders)
  }
  /* =============== */
}
