



























import { Component, Vue } from 'vue-property-decorator'
import * as api from '@/api'

const Extender = Vue.extend()

@Component
export default class SubjectAreaFilter extends Extender {
  loading = false
  selectedSubjectAreaId = null

  created() {
    api.SubjectArea.getAll().then(res => {
      this.$store.dispatch('Search/setSubjectAreas', res.data)
    })
  }

  get subjectAreas() {
    return this.$store.state.Search.subjectAreas
  }

  onChange() {
    this.$store.dispatch(
      'Search/setSelectedSubjectAreaId',
      this.selectedSubjectAreaId
    )
  }
}
