import Vue from 'vue'
import VueRouter from 'vue-router'

import { mySAndL } from '@/router/routes/mySAndL'
import { catalog } from '@/router/routes/catalog'
import { admin } from '@/router/routes/admin'
import { helpcenter } from '@/router/routes/helpcenter'
import { mySAndLRoutes } from '@/constants/routes'

Vue.use(VueRouter)

const routes = [
  ...mySAndL,
  ...catalog,
  ...admin,
  ...helpcenter,
  {
    path: '/',
    redirect: {
      name: mySAndLRoutes.myConfigurations
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  routes
})

export default router
