import { render, staticRenderFns } from "./Videos.vue?vue&type=template&id=0f0a976b&scoped=true&"
import script from "./Videos.vue?vue&type=script&lang=ts&"
export * from "./Videos.vue?vue&type=script&lang=ts&"
import style0 from "./Videos.vue?vue&type=style&index=0&id=0f0a976b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f0a976b",
  null
  
)

export default component.exports