export const mySAndLRoutes = {
  myConfigurations: 'myConfigurations',
  createConfiguration: 'createConfiguration',
  configurationInformation: 'configurationInformation',
  configurationChapters: 'configurationChapters',
  configurationSpecifications: 'configurationSpecifications',
  configurationOrder: 'configurationOrder',
  myOrders: 'myOrders'
}

export const catalogRoutes = {
  catalogOverview: 'catalogOverview',
  catalogInformation: 'catalogInformation',
  catalogChapters: 'catalogChapters',
  catalogSelection: 'catalogSelection'
}

export const adminRoutes = {
  usersOverview: 'usersOverview',
  ordersOverview: 'ordersOverview',
  resourcesOverview: 'resourcesOverview',
  createResource: 'createResource',
  resourceInformation: 'resourceInformation',
  resourceChapters: 'resourceChapters'
}

export const helpcenterRouters = {
  videos: 'videos',
  faq: 'faq'
}
