import { IChapter } from '@/interfaces'

export const EChapter: IChapter = {
  id: '',
  title: '',
  catalogResource: '',
  previewFileUrl: '',
  sourceFileUrl: '',
  asset: '',
  keywords: []
}
