







import { Component, Vue } from 'vue-property-decorator'

const Extender = Vue.extend({
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  }
})

@Component
export default class Link extends Extender {}
