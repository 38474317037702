

















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import {
  PDFDocumentProxy,
  PDFPageProxy,
  PDFRenderParams,
  getDocument
} from 'pdfjs-dist/webpack'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */

const Extender = Vue.extend({
  components: {
    MoonLoader
  }
})

@Component
export default class PDFPreview extends Extender {
  pdfDoc: PDFDocumentProxy | null = null
  currentPage = 1
  scale = 0
  numpages = 0
  displayWidth: number | null = null
  loading = false
  pageRendering = false
  pageNumPending: number | null = null
  renderTask: any = null
  context: any = null
  canvas: any = null

  mounted() {
    const AuthStr = this.$auth.user.access_token
    this.loading = true
    const scales = { 1: 3.2, 2: 4 },
      defaultScale = 3
    this.scale = scales[window.devicePixelRatio] || defaultScale
    getDocument({
      url: this.modal.pdfUrl,
      withCredentials: false,
      httpHeaders: { Authorization: AuthStr }
    }).promise.then((pdf: PDFDocumentProxy) => {
      this.loading = false
      this.$nextTick(() => {
        this.canvas = document.getElementById('pdf') as HTMLCanvasElement
        this.context = this.canvas.getContext('2d')
        if (this.pdfDoc) {
          this.pdfDoc.destroy()
        }

        this.pdfDoc = pdf
        this.numpages = pdf.numPages
        this.renderPage()
      })
    })
    window.addEventListener('keyup', this.handleKeypres)
  }

  destroy() {
    window.removeEventListener('keyup', this.handleKeypres)
    if (this.pdfDoc) {
      this.pdfDoc.destroy()
    }
  }

  handleKeypres(event: any) {
    if (event.keyCode == 39) {
      this.nextPage()
    }
    if (event.keyCode == 37) {
      this.prevPage()
    }
    if (event.keyCode == 48) {
      this.fitToScale()
    }
    if (event.keyCode == 189) {
      this.zoomOut()
    }
    if (event.keyCode == 187) {
      this.zoomIn()
    }
    if (event.keyCode == 27) {
      this.$store.dispatch('Modals/setModal', null)
    }
  }

  @Watch('currentPage')
  watchCurrentPageChange() {
    this.renderPage()
  }

  zoomIn() {
    if (this.displayWidth !== null && this.displayWidth < 5) {
      this.displayWidth += 0.5
    }

    this.renderPage()
  }
  zoomOut() {
    if (this.displayWidth !== null && this.displayWidth > 1) {
      this.displayWidth -= 0.5
    }

    this.renderPage()
  }

  fitToScale() {
    this.displayWidth = null
    this.renderPage()
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--
    }
  }

  nextPage() {
    if (this.currentPage < this.numpages) {
      this.currentPage++
    }
  }

  renderPage() {
    this.pageRendering = true
    const wrapper = document.getElementById('pdfviewWrapper')
    let bounds = { height: 0 }
    if (wrapper !== null) {
      bounds = wrapper.getBoundingClientRect()
    }

    if (this.pdfDoc) {
      this.pdfDoc.getPage(this.currentPage).then((page: PDFPageProxy) => {
        const viewport = page.getViewport({ scale: this.scale })

        if (this.context !== null) {
          this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.context.beginPath()
        }

        this.canvas.width = viewport.width
        this.canvas.height = viewport.height

        if (this.displayWidth == null && bounds.height !== 0) {
          this.displayWidth = (this.scale * bounds.height) / viewport.height
        }

        if (this.displayWidth !== null) {
          this.canvas.style.width = `${(viewport.width * this.displayWidth) /
            this.scale}px`
          this.canvas.style.height = `${(viewport.height * this.displayWidth) /
            this.scale}px`
        }

        const renderContext = {
          canvasContext: this.context,
          viewport: viewport
        } as PDFRenderParams

        this.renderTask = page.render(renderContext)
        this.renderTask.promise.then(() => {
          this.pageRendering = false
          if (this.pageNumPending !== null) {
            this.currentPage = this.pageNumPending
            this.renderPage()
            this.pageNumPending = null
          }
        })
      })
    }
  }

  get modal() {
    return this.$store.state.Modals.modal
  }
}
