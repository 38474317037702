export enum EModal {
  DELETE_RESOURCE_CONFIRM = 'DELETE_RESOURCE_CONFIRM',
  DELETE_CONFIGURATION_CONFIRM = 'DELETE_CONFIGURATION_CONFIRM',
  DELETE_RESOURCE_CHAPTER_CONFIRM = 'DELETE_RESOURCE_CHAPTER_CONFIRM',
  PUBLISH_RESOURCE_CONFIRM = 'PUBLISH_RESOURCE_CONFIRM',
  ADD_OWN_CONTENT_TO_S_AND_L = 'ADD_OWN_CONTENT_TO_S_AND_L',
  CATALOG_SELECTION_SUCCESS = 'CATALOG_SELECTION_SUCCESS',
  CATALOG_SELECTION_FAIL = 'CATALOG_SELECTION_FAIL',
  PREVIEW_MODAL = 'PREVIEW_MODAL',
  DELETE_CONFIGURATION_COMPONENT = 'DELETE_CONFIGURATION_COMPONENT',
  ORDER_S_AND_L_CONFIRM = 'ORDER_S_AND_L_CONFIRM',
  VIDEO_MODAL = 'VIDEO_MODAL',
  USER_REGISTERED = 'USER_REGISTERED',
  REVISE_RESOURCE_CONFIRM = 'REVISE_RESOURCE_CONFIRM'
}
