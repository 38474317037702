import Resources from '@/views/admin/Resources.vue'
import Users from '@/views/admin/Users.vue'
import Orders from '@/views/admin/Orders.vue'
import ResourceDetail from '@/views/admin/ResourceDetail.vue'
import CreateCatalogResource from '@/views/admin/CreateResource.vue'
import ResourceInformation from '@/views/admin/ResourceInformation.vue'
import ResourceChapters from '@/views/admin/ResourceChapters.vue'
import { adminRoutes } from '@/constants/routes'

export const admin = [
  {
    path: '/admin/users',
    component: Users,
    name: adminRoutes.usersOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/orders',
    component: Orders,
    name: adminRoutes.ordersOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/resources',
    component: Resources,
    name: adminRoutes.resourcesOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/resources/create',
    component: CreateCatalogResource,
    name: adminRoutes.createResource,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/resource/:resourceId',
    component: ResourceDetail,
    children: [
      {
        path: '',
        component: ResourceInformation,
        name: adminRoutes.resourceInformation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'chapters',
        component: ResourceChapters,
        name: adminRoutes.resourceChapters,
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
]
