






























































import { Vue, Component } from 'vue-property-decorator'
import { IUser } from '@/interfaces'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import User from '@/components/admin/User.vue'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader,
    User,
    LabelOutInput
  }
})

@Component
export default class Users extends Extender {
  selectedUsers = []
  searchValue = ''
  teacherFilterValue = ''

  optionAll = 'ALL'
  optionTeachers = 'TEACHERS'
  optionNonTeachers = 'NON-TEACHERS'

  created() {
    this.teacherFilterValue = this.optionAll

    api.User.getAll().then(res => {
      this.$store.dispatch('Users/setUsers', res.data)
    })
  }

  get users() {
    // Sort users by full name alphabetically
    const users = this.$store.state.Users.users.sort((a: IUser, b: IUser) => {
      const aFullName = a.fullName.toLowerCase()
      const bFullName = b.fullName.toLowerCase()

      return aFullName > bFullName ? 1 : bFullName > aFullName ? -1 : 0
    })

    return users.filter((user: IUser) => {
      const search = this.searchValue.toLowerCase()

      if (
        this.teacherFilterValue === this.optionTeachers &&
        !user.internalRoles.includes('ROLE_TEACHER')
      ) {
        return
      }

      if (
        this.teacherFilterValue === this.optionNonTeachers &&
        user.internalRoles.includes('ROLE_TEACHER')
      ) {
        return
      }

      if (user.fullName.toLowerCase().includes(search)) {
        return user
      }

      if (user.username.toLowerCase().includes(search)) {
        return user
      }
    })
  }
}
