












































import { Component, Vue } from 'vue-property-decorator'
import { topnavs } from '@/constants/topnavs'
import { mySAndLRoutes } from '@/constants/routes'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import SAndLGrid from '@/components/select-and-learns/SAndLGrid.vue'
import PageInfo from '@/components/pages/PageInfo.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    TopNav,
    BPrimary,
    PageHeader,
    SAndLGrid,
    PageInfo
  }
})

@Component
export default class Configurations extends Extender {
  mySAndLRoutes = mySAndLRoutes
  topnavs = topnavs

  created() {
    api.Configuration.getAll().then(res => {
      this.$store.dispatch('Configurations/setConfigurations', res.data)
    })
  }

  get configurations() {
    return this.$store.state.Configurations.configurations
  }
}
