import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { IChapter } from '@/interfaces'

@Module({ namespaced: true })
export class NewConfiguration extends VuexModule {
  selectedChapters: Array<IChapter> = []
  violations: Array<object> = []

  /* === MUTATIONS === */
  @Mutation
  SET_CHAPTERS(chapters: Array<IChapter>) {
    this.selectedChapters = chapters
  }
  @Mutation
  PUSH_SELECTED_CHAPTER(chapter: IChapter) {
    this.selectedChapters.push(chapter)
  }
  @Mutation
  SET_SELECTED_CHAPTERS(chapters: Array<IChapter> | []) {
    this.selectedChapters = chapters
  }
  @Mutation
  SET_VIOLATIONS(violations: Array<object>) {
    this.violations = violations
  }
  /* ================= */

  /* === ACTIONS === */
  @Action
  addChapter(chapter: IChapter) {
    this.context.commit('PUSH_SELECTED_CHAPTER', chapter)
  }
  @Action
  removeChapter(chapter: IChapter) {
    this.context.commit(
      'SET_CHAPTERS',
      this.selectedChapters.filter(el => el.id !== chapter.id)
    )
  }
  @Action
  setSelectedChapters(chapters: Array<IChapter> | []) {
    this.context.commit('SET_SELECTED_CHAPTERS', chapters)
  }
  @Action
  setViolations(violations: Array<object>) {
    this.context.commit('SET_VIOLATIONS', violations)
  }
  /* =============== */
}
