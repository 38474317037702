import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { Configurations } from './modules/Configurations'
import { Resources } from './modules/Resources'
import { Modals } from './modules/Modals'
import { NewConfiguration } from './modules/NewConfiguration'
import { Catalogs } from './modules/Catalogs'
import { Search } from './modules/Search'
import { Users } from './modules/Users'
import { Orders } from './modules/Orders'
import { Videos } from './modules/Videos'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'vuex',
      paths: ['NewConfiguration']
    })
  ],
  modules: {
    Configurations,
    Resources,
    Modals,
    NewConfiguration,
    Catalogs,
    Search,
    Users,
    Orders,
    Videos
  }
})
