















import { Component, Vue } from 'vue-property-decorator'
import SearchChapterItem from '@/components/search/SearchChapterItem.vue'

const Extender = Vue.extend({
  props: {
    data: {
      type: Array
    }
  },
  components: {
    SearchChapterItem
  }
})

@Component
export default class SearchChapter extends Extender {
  chapters = this.data.length > 3 ? this.data.slice(0, 3) : this.data
  showMore = this.amountChapters > 3

  get amountChapters() {
    return this.data.length
  }

  showAll() {
    this.chapters = this.data
    this.showMore = false
  }
}
