


















import { Component, Vue } from 'vue-property-decorator'
import BPrimary from './BPrimary.vue'

const Extender = Vue.extend({
  components: {
    BPrimary
  },
  props: {
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

@Component
export default class ConfirmButtons extends Extender {}
