
























import { Component, Vue } from 'vue-property-decorator'
import ConfirmButtons from '@/components/buttons/ConfirmButtons.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    ConfirmButtons
  }
})

@Component
export default class DeleteResourceChapterConfirm extends Extender {
  error = ''

  onDelete() {
    const resourceId = this.$route.params.resourceId

    api.Chapter.delete(this.selectedChapter.id)
      .then(() => {
        api.Resource.get(resourceId).then(res => {
          this.$store.dispatch('Resources/setSelectedResource', res.data)
          this.$store.dispatch('Modals/setModal', null)
        })
      })
      .catch(() => {
        this.error = this.$i18n.t('error_delete_configuration') as string
      })
  }

  get selectedChapter() {
    return this.$store.state.Resources.selectedChapter
  }
}
