




















import { Component, Vue } from 'vue-property-decorator'

const Extender = Vue.extend({
  props: {
    value: {
      required: true,
      type: [String, Number]
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    error: {
      required: false,
      type: Object
    },
    type: {
      type: String,
      required: false,
      default: 'input',
      validator: val => ['input', 'textarea'].indexOf(val) !== -1
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

@Component
export default class Currency extends Extender {
  input: string | number = 0

  created() {
    this.input = this.value
  }
}
