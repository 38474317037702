import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { IModal } from '@/interfaces'

@Module({ namespaced: true })
export class Modals extends VuexModule {
  modal: IModal | null = null

  @Mutation
  SET_MODAL(modal: IModal | null) {
    this.modal = modal
  }

  @Action
  setModal(modal: IModal | null) {
    this.context.commit('SET_MODAL', modal)
  }
}
