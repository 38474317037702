import { render, staticRenderFns } from "./ConfigurationOrder.vue?vue&type=template&id=0b590b90&scoped=true&"
import script from "./ConfigurationOrder.vue?vue&type=script&lang=ts&"
export * from "./ConfigurationOrder.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b590b90",
  null
  
)

export default component.exports