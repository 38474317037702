





















import { Component, Vue } from 'vue-property-decorator'
import SideBar from '@/components/navigations/sidebar/SideBar.vue'
import Composer from '@/components/Composer.vue'
import Modal from '@/components/Modal.vue'

const Extender = Vue.extend({
  components: {
    SideBar,
    Composer,
    Modal
  }
})

@Component
export default class App extends Extender {
  get composer() {
    return this.$route.meta.composer
  }

  get selectedChapters() {
    return this.$store.state.NewConfiguration.selectedChapters
  }

  get modal() {
    return this.$store.state.Modals.modal
  }

  // TODO: Temp hardcoded stuff.Should be triggered by the user trying to access a protected route
  // mounted() {
  //   if (
  //     this.$auth.isAuthenticated === false &&
  //     this.$route.name !== 'callback'
  //   ) {
  //     this.$auth.signIn()
  //   } else {
  //     console.log(this.$auth.user)
  //   }
  // }
}
