





























import { Component, Vue } from 'vue-property-decorator'
import * as api from '@/api'

const Extender = Vue.extend()

@Component
export default class SearchSort extends Extender {
  selectedSort = ''

  created() {
    this.selectedSort = this.$store.state.Search.sort
  }
  onChange() {
    this.$store.dispatch('Search/setSelectedSort', this.selectedSort)

    api.Catalog.getAll({
      searchValue: this.$store.state.Search.activeSearch,
      category: this.$store.state.Search.selectedFilterValue,
      sort: this.$store.state.Search.sort
    }).then(res => {
      this.$store.dispatch('Catalogs/setCatalogs', res.data)
      // this.allCatalogs = res.data
    })
  }
}
