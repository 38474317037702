
























import { Component, Vue } from 'vue-property-decorator'
import ResourceChapterItem from '@/components/chapters/ResourceChapterItem.vue'
import CreateResourceChapter from '@/components/CreateResourceChapter.vue'
import InfoBox from '@/components/InfoBox.vue'

const Extender = Vue.extend({
  components: {
    ResourceChapterItem,
    CreateResourceChapter,
    InfoBox
  }
})

@Component
export default class ResourceChapters extends Extender {
  get selectedResource() {
    return this.$store.state.Resources.selectedResource
  }

  get resourceChapters() {
    return this.selectedResource.chapters
  }
}
