






















import { Component, Vue } from 'vue-property-decorator'
import vue2Dropzone from 'vue2-dropzone'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

const Extender = Vue.extend({
  components: {
    vue2Dropzone,
    MoonLoader
  },
  props: {
    url: {
      type: String,
      required: true
    },
    autoUpload: {
      type: Boolean,
      required: true
    },
    height: {
      type: String,
      required: false
    },
    maxFilesize: {
      type: Number,
      required: false,
      default: 20
    }
  }
})

@Component
export default class PDFUpload extends Extender {
  processing = false
  dropzoneOptions = {
    url: '/',
    maxFilesize: 20,
    maxFiles: 1,
    acceptedFiles: 'application/pdf',
    autoProcessQueue: false,
    autoQueue: true,
    addRemoveLinks: true,
    createImageThumbnails: false,
    headers: { Authorization: '' }
  }

  created() {
    this.dropzoneOptions.url = this.url
    this.dropzoneOptions.autoProcessQueue = this.autoUpload
    this.dropzoneOptions.headers.Authorization = this.$auth.user.access_token
    this.dropzoneOptions.maxFilesize = this.maxFilesize
  }

  fileAdded(pdf: File) {
    this.processing = true

    if (!this.autoUpload) {
      this.$emit('pdfAdded', pdf)
    }
  }

  success(file: File, resp: { message: string; chapterId: string }) {
    this.$emit('fileUploadSuccess', file, resp)

    if (!this.autoUpload) {
      this.processing = false
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  error(_0: null, message: any) {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const dropzone: any = this.$refs.pdfUpload

    if (message.violations) {
      this.$toasted.show(message.violations[0].message as string)
    }

    dropzone.removeAllFiles(true)
    this.processing = false
  }
}
