








import { Component, Vue } from 'vue-property-decorator'

const Props = Vue.extend({
  props: {
    type: {
      required: true,
      type: String,
      validator: val => ['draft', 'order'].indexOf(val) !== -1
    },
    title: {
      type: String,
      required: true
    }
  }
})

@Component
export default class Label extends Props {}
