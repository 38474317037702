























import { Component, Vue } from 'vue-property-decorator'
import { IUser } from '@/interfaces'
import Checkbox from '@/components/inputs/Checkbox.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    Checkbox
  },
  props: {
    user: {
      type: Object as () => IUser,
      required: true
    }
  }
})

@Component
export default class User extends Extender {
  toggle(checked: boolean) {
    const id = this.user.id

    if (checked) {
      this.demoteTeacher(id)
    } else {
      this.promoteTeacher(id)
    }
  }

  promoteTeacher(id: string) {
    api.User.promoteTeacher(id)
      .then(() => {
        return this.fetchUsers()
      })
      .then(() => {
        this.$toasted.show(
          this.$t('promoted_teacher', {
            teacher: this.user.fullName
          }) as string,
          {
            type: 'success'
          }
        )
      })
      .catch(() => {
        this.$toasted.show(
          this.$t('error_promoting_teacher', {
            teacher: this.user.fullName
          }) as string
        )
      })
  }

  demoteTeacher(id: string) {
    api.User.demoteTeacher(id)
      .then(() => {
        return this.fetchUsers()
      })
      .then(() => {
        this.$toasted.show(
          this.$i18n.t('demoted_teacher', {
            teacher: this.user.fullName
          }) as string,
          {
            type: 'success'
          }
        )
      })
      .catch(() => {
        this.$toasted.show(
          this.$t('error_demoting_teacher', {
            teacher: this.user.fullName
          }) as string
        )
      })
      .finally(() => {
        document.body.style.cursor = 'auto'
      })
  }

  async fetchUsers() {
    return api.User.getAll().then(res => {
      this.$store.dispatch('Users/setUsers', res.data)
    })
  }

  get userIsTeacher() {
    return this.user.internalRoles.includes('ROLE_TEACHER')
  }
}
