



















import { Component, Vue } from 'vue-property-decorator'
import { topnavs } from '@/constants/topnavs'
import AuthButton from '@/components/navigations/top-nav/AuthButton.vue'

const Extender = Vue.extend({
  components: {
    AuthButton
  },
  props: {
    nav: {
      type: String,
      required: true
    }
  }
})

@Component
export default class TopNav extends Extender {
  topnavs = topnavs
}
