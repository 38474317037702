




















import { Component, Vue } from 'vue-property-decorator'

const Props = Vue.extend({
  props: {
    authors: {
      required: true,
      type: Array
    }
  }
})

@Component
export default class Authors extends Props {
  get remainingAuthors() {
    let liItems = ``

    for (let i = 0, ilen = this.authors.length; i < ilen; i++) {
      if (i > 1) {
        liItems += `<li>${this.authors[i]}</li>`
      }
    }

    return liItems
  }
}
