








































import { Component, Vue } from 'vue-property-decorator'
import { EChapter } from '@/constants/emptyObjects'
import { IChapter } from '@/interfaces'
import { required } from 'vuelidate/lib/validators'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import Tags from '@/components/inputs/Tags.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import cloneDeep from 'lodash.clonedeep'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    LabelOutInput,
    Tags,
    BPrimary
  },
  validations: {
    newChapter: {
      title: { required },
      keywords: { required }
    }
  }
})

@Component
export default class CreateResourceChapter extends Extender {
  newChapter: IChapter = cloneDeep(EChapter)
  loading = false

  created() {
    this.fetchResource()
  }

  addNewChapter() {
    this.newChapter.catalogResource = this.$route.params.resourceId

    this.$v.newChapter.$touch()

    if (this.$v.newChapter.$error) return

    this.loading = true

    api.Chapter.post(this.newChapter)
      .then(() => {
        this.fetchResource()
      })
      .finally(() => {
        this.loading = false
      })
  }

  fetchResource() {
    api.Resource.get(this.$route.params.resourceId).then(res => {
      this.$store.dispatch('Resources/setSelectedResource', res.data)
      this.newChapter = cloneDeep(EChapter)
      this.$v.newChapter.$reset()
    })
  }

  get selectedResource() {
    return this.$store.state.Resources.selectedResource
  }
}
