








































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/pages/PageHeader.vue'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import SAndLGrid from '@/components/select-and-learns/SAndLGrid.vue'
import PageInfo from '@/components/pages/PageInfo.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    PageHeader,
    TopNav,
    PageInfo,
    SAndLGrid
  }
})

@Component
export default class MyOrders extends Extender {
  created() {
    api.Configuration.getOrdered().then(res => {
      this.$store.dispatch('Configurations/setOrderedConfigurations', res.data)
    })
  }

  get orderedConfigurations() {
    return this.$store.state.Configurations.orderedConfigurations
  }
}
