



























import { Component, Vue } from 'vue-property-decorator'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import Video from '@/components/Video.vue'
import { IModal } from '../../interfaces'
import { EModal } from '../../enums'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader,
    Video
  }
})

@Component
export default class Videos extends Extender {
  videoClick(video: null) {
    const modal: IModal = {
      type: EModal.VIDEO_MODAL,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Videos/setSelectedVideo', video).then(() => {
      this.$store.dispatch('Modals/setModal', modal)
    })
  }

  get videos() {
    return this.$store.state.Videos.videos
  }
}
