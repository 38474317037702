


















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { IModal } from '@/interfaces'
import { EModal } from '@/enums'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import faq from '@/constants/faq.json'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader
  }
})

@Component
export default class FAQ extends Extender {
  faq = faq
  selected = ''

  faqClick(faqTitle: string) {
    if (this.selected === faqTitle) {
      this.selected = ''
    } else {
      this.selected = faqTitle
    }
  }

  @Watch('roles')
  watchRolesChange() {
    const registerModalChecked = JSON.parse(
      localStorage.getItem('REGISTER_MODAL_CHECKED') as string
    )

    if (!this.isTeacher && !this.isAdmin && !registerModalChecked) {
      const modal: IModal = {
        type: EModal.USER_REGISTERED,
        edit: false,
        closable: true
      }

      this.$store.dispatch('Modals/setModal', modal)
    }
  }

  get isTeacher() {
    const { roles } = this.$auth.user.profile
    return roles.indexOf('ROLE_TEACHER') !== -1
  }

  get isAdmin() {
    const { roles } = this.$auth.user.profile
    return roles.indexOf('ROLE_ADMIN') !== -1
  }

  get roles() {
    return this.$auth.user.profile.roles
  }
}
