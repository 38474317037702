

























































import { Component, Vue } from 'vue-property-decorator'
import { catalogRoutes } from '@/constants/routes'
import BPrimary from '@/components/buttons/BPrimary.vue'

const Extender = Vue.extend({
  components: {
    BPrimary
  }
})

@Component
export default class Composer extends Extender {
  catalogRoutes = catalogRoutes
  error: string | null = null

  get selectedChapters() {
    return this.$store.state.NewConfiguration.selectedChapters
  }

  get chapterAmount(): number {
    return this.selectedChapters.length
  }

  get bookAmount(): number {
    const amount = [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...new Set(this.selectedChapters.map((data: any) => data.catalogResource))
    ]
    return amount.length
  }
}
