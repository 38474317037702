






































import { Component, Vue } from 'vue-property-decorator'
import { IModal, IConfiguration } from '@/interfaces'
import { EModal } from '@/enums'
import BPrimary from '@/components/buttons/BPrimary.vue'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    BPrimary,
    MoonLoader
  }
})

@Component
export default class BConfigurationPreview extends Extender {
  error = ''
  loading = false
  previewUrl: string | null = null
  status = ''
  showNotification = false
  GENERATING_TIMEOUT = 3000

  created() {
    this.checkStatus(this.configuration)
  }

  clickHandler() {
    if (this.previewUrl) {
      // Open previewFileUrl
      const modal: IModal = {
        type: EModal.PREVIEW_MODAL,
        edit: false,
        closable: true,
        pdfUrl: this.previewUrl
      }

      this.$store.dispatch('Modals/setModal', modal)
    } else {
      // Start generating preview
      this.showNotification = true
      this.postPreview()
    }
  }

  // Post preview to backend
  postPreview() {
    this.loading = true

    api.Preview.post(this.configurationId)
      .then(() => {
        this.getPreviewStatus()
      })
      .catch(() => {
        this.loading = false
        this.$toasted.show(
          this.$i18n.t('error_configuration_preview') as string
        )
      })
  }

  // Get preview status, keep long polling for results
  getPreviewStatus() {
    api.Configuration.get(this.configurationId)
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .then((response: any) => {
        if (response && response.data && response.data.status) {
          this.checkStatus(response.data)
        }
      })
      .catch(() => {
        this.loading = false
        this.$toasted.show(
          this.$i18n.t('error_configuration_preview') as string
        )
      })
  }

  // Status checker
  checkStatus(response: IConfiguration) {
    switch (response.status) {
      case 'IN_DRAFT':
        this.status = 'IN_DRAFT'
        if (!response.previewFileUrl) {
          this.loading = false
        }
        break
      case 'GENERATING_PREVIEW':
        this.status = 'GENERATING_PREVIEW'
        this.loading = true
        setTimeout(() => {
          this.getPreviewStatus()
        }, this.GENERATING_TIMEOUT)
        break
      case 'PREVIEW_GENERATED':
        if (response.previewFileUrl) {
          this.status = 'PREVIEW_GENERATED'
          this.loading = false
          this.previewUrl = response.previewFileUrl

          if (this.showNotification) {
            this.$toasted.show(
              this.$i18n.t('preview_generated_success') as string,
              {
                type: 'success'
              }
            )

            this.showNotification = false
          }
        } else {
          this.loading = true
          setTimeout(() => {
            this.getPreviewStatus()
          }, this.GENERATING_TIMEOUT)
        }
        break
      default:
        this.loading = false
        this.$toasted.show(
          this.$i18n.t('error_configuration_preview') as string
        )
    }
  }

  get configurationId() {
    return this.$route.params.configurationId
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }

  get btnColor() {
    if (this.status === 'PREVIEW_GENERATED') {
      return 'white'
    }

    return 'primary'
  }

  get iconColor() {
    if (this.status === 'PREVIEW_GENERATED') {
      return 'icon-neon'
    }

    return 'icon-snow'
  }
}
