import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { ISubjectArea, IPublisher, IResource, IChapter } from '@/interfaces'

@Module({ namespaced: true })
export class Resources extends VuexModule {
  subjectAreas: Array<ISubjectArea> = []
  publishers: Array<IPublisher> = []
  resources: Array<IResource> = []
  selectedResource: IResource | null = null
  selectedChapter: IChapter | null = null

  /* === MUTATIONS === */
  @Mutation
  SET_RESOURCES(resources: Array<IResource>) {
    this.resources = resources
  }
  @Mutation
  SET_SELECTED_RESOURCE(resource: IResource | null) {
    this.selectedResource = resource
  }
  @Mutation
  SET_SUBJECT_AREAS(apiSubjectAreas: Array<ISubjectArea>) {
    this.subjectAreas = apiSubjectAreas
  }
  @Mutation
  SET_PUBLISHERS(publishers: Array<IPublisher>) {
    this.publishers = publishers
  }
  @Mutation
  SET_SELECTED_CHAPTER(chapter: IChapter | null) {
    this.selectedChapter = chapter
  }
  /* ================= */

  /* === ACTIONS === */
  @Action
  setResources(resources: Array<IResource>) {
    this.context.commit('SET_RESOURCES', resources)
  }
  @Action
  setSelectedResource(resource: IResource) {
    this.context.commit('SET_SELECTED_RESOURCE', resource)
  }
  @Action
  setSubjectAreas(subjectAreas: Array<ISubjectArea>) {
    this.context.commit('SET_SUBJECT_AREAS', subjectAreas)
  }
  @Action
  setPublishers(publishers: Array<IPublisher>) {
    this.context.commit('SET_PUBLISHERS', publishers)
  }
  @Action
  clearSelectedResource() {
    this.context.commit('SET_SELECTED_RESOURCE', null)
  }
  @Action
  setSelectedChapter(chapter: IChapter | null) {
    this.context.commit('SET_SELECTED_CHAPTER', chapter)
  }
  /* =============== */
}
