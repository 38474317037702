














































import { Component, Vue } from 'vue-property-decorator'
import { adminRoutes } from '@/constants/routes'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import PageHeaderNav from '@/components/navigations/page-header-nav/PageHeaderNav.vue'
import * as api from '@/api'

@Component({
  components: {
    TopNav,
    PageHeader,
    PageHeaderNav
  }
})
export default class ResourceDetail extends Vue {
  adminRoutes = adminRoutes

  created() {
    api.Resource.get(this.$route.params.resourceId).then(res => {
      this.$store.dispatch('Resources/setSelectedResource', res.data)
    })
  }

  get selectedResource() {
    return this.$store.state.Resources.selectedResource
  }
}
