


















































import { Vue, Component } from 'vue-property-decorator'
import { IOrder } from '@/interfaces'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import Order from '@/components/admin/Order.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader,
    LabelOutInput,
    Order
  }
})

@Component
export default class Users extends Extender {
  searchValue = ''

  created() {
    api.Order.getAll().then(res => {
      this.$store.dispatch('Orders/setOrders', res.data)
    })
  }

  get orders() {
    const orders = this.$store.state.Orders.orders

    return orders.filter((order: IOrder) => {
      const search = this.searchValue.toLowerCase()

      if (order.configuration.title.toLowerCase().includes(search)) {
        return order
      }

      if (order.configuration.authorText.toLowerCase().includes(search)) {
        return order
      }

      if (order.createdAt.toLowerCase().includes(search)) {
        return order
      }
    })
  }
}
