import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { IUser } from '@/interfaces'

@Module({ namespaced: true })
export class Users extends VuexModule {
  users: Array<IUser> = []

  /* === MUTATIONS === */
  @Mutation
  SET_USERS(users: Array<IUser>) {
    this.users = users
  }
  /* ================= */

  /* === ACTIONS === */
  @Action
  setUsers(users: Array<IUser>) {
    this.context.commit('SET_USERS', users)
  }
  /* =============== */
}
