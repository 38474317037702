
















import { Component, Vue } from 'vue-property-decorator'
import { catalogRoutes } from '@/constants/routes'
import SearchChapter from '@/components/search/SearchChapter.vue'

const Extender = Vue.extend({
  props: {
    item: {
      type: Object
    }
  },
  components: {
    SearchChapter
  }
})

@Component
export default class SearchBookItem extends Extender {
  catalogRoutes = catalogRoutes
}
