import axios from 'axios'
import {
  IResource,
  IConfiguration,
  IChapter,
  IUserComponent,
  ISpecification
} from '@/interfaces'

const base = process.env.VUE_APP_API_URL
const configuration = base + '/configuration'
const institute = base + '/institute'
const subjectArea = base + '/subject-area'
const publisher = base + '/publisher'
const resource = base + '/catalog-resource'
const catalog = base + '/catalog/search'
const chapter = base + '/chapter'
const search = base + '/catalog/search'
const user = base + '/user'
const usercontext = base + '/user-context'
const order = base + '/order'

/* Configuration */
export const Configuration = {
  getAll: async () => {
    return await axios.get(configuration)
  },
  getOrdered: async () => {
    return await axios.get(configuration + '-ordered')
  },
  get: async (id: string) => {
    return await axios.get(configuration + '/' + id)
  },
  post: async (formData: IConfiguration) => {
    return await axios.post(configuration, formData)
  },
  postChapterComponent: async (id: string, chapters: Array<string>) => {
    return await axios.post(configuration + '/' + id + '/chapter-component', {
      chapters
    })
  },
  postUserComponent: async (id: string, formData: IUserComponent) => {
    return await axios.post(
      configuration + '/' + id + '/user-content-component',
      formData
    )
  },
  orderComponents: async (
    id: string,
    components: { orderedComponents: Array<string> }
  ) => {
    return await axios.post(
      configuration + '/' + id + '/component/order',
      components
    )
  },
  update: async (id: string, formData: IConfiguration) => {
    return await axios.patch(configuration + '/' + id, formData)
  },
  delete: async (id: string) => {
    return await axios.delete(configuration + '/' + id)
  },
  deleteComponent: async (configurationId: string, componentId: string) => {
    return axios.delete(
      `${configuration}/${configurationId}/component/${componentId}`
    )
  },
  postSpecifications: async (id: string, specifications: ISpecification) => {
    return await axios.post(
      `${configuration}/${id}/specification`,
      specifications
    )
  },
  getSpecifications: async (id: string) => {
    return await axios.get(`${configuration}/${id}/specification`)
  },
  getSellingPrice: async (id: string) => {
    return await axios.get(`${configuration}/${id}/selling-price`)
  },
  orderSAndL: async (id: string) => {
    return axios.post(`${configuration}/${id}/order`)
  }
}

/* Institute */
export const Institute = {
  get: async () => axios.get(institute)
}

/* Resource */
export const Resource = {
  getAll: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(resource)
        .then(res => {
          resolve(
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            res.data.sort((a: any, b: any) => {
              const aTitle = a.title.toLowerCase()
              const bTitle = b.title.toLowerCase()
              if (aTitle < bTitle) return -1
              if (aTitle > bTitle) return 1
              return 0
            })
          )
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  get: async (id: string) => {
    return await axios.get(resource + '/' + id)
  },
  post: async (formData: IResource) => {
    return await axios.post(resource, formData)
  },
  update: async (id: string, formData: IResource) => {
    return await axios.patch(resource + '/' + id, formData)
  },
  delete: async (id: string) => {
    return await axios.delete(resource + '/' + id)
  },
  publish: async (id: string) => {
    return await axios.post(resource + '/' + id + '/publish')
  },
  revise: async (id: string) => {
    return await axios.post(resource + '/' + id + '/revise')
  }
}

/* Chapter */
export const Chapter = {
  post: async (formData: IChapter) => {
    return await axios.post(chapter, formData)
  },
  update: async (formData: IChapter) => {
    return axios.patch(`${chapter}/${formData.id}`, formData)
  },
  delete: async (id: string) => {
    return axios.delete(`${chapter}/${id}`)
  }
}

/* Subject area */
export const SubjectArea = {
  getAll: async () => {
    return await axios.get(subjectArea)
  }
}

/* Publisher */
export const Publisher = {
  getAll: async () => {
    return await axios.get(publisher)
  }
}

/* Catalog */
export const Catalog = {
  getAll: async (query: {
    searchValue: string
    category: string
    sort: string
  }) => {
    return await axios.post(catalog, query)
  }
}

/* Search */
export const Search = {
  post: async (formData: {
    searchValue: string
    category: string
    sort: string
  }) => {
    return await axios.post(search, formData)
  }
}

/* Preview */
export const Preview = {
  post: async (id: string) => {
    return await axios.post(configuration + '/' + id + '/preview')
  }
}

/* User */
export const User = {
  getAll: async () => {
    return await axios.get(user)
  },
  promoteTeacher: async (id: string) => {
    return await axios.post(user + '/' + id + '/promote-teacher')
  },
  demoteTeacher: async (id: string) => {
    return await axios.post(user + '/' + id + '/demote-teacher')
  }
}

/* UserContext */
export const Usercontext = {
  get: async (token: string) => {
    const AuthStr = 'Bearer '.concat(token)
    return await axios.get(usercontext, { headers: { Authorization: AuthStr } })
  }
}

export const Order = {
  getAll: async () => {
    return await axios.get(order)
  }
}

export const Asset = {
  download: async (downloadUrl: string) => {
    return await axios.get(downloadUrl, { responseType: 'blob' })
  }
}
