










































































































import { Component, Vue } from 'vue-property-decorator'
import { mySAndLRoutes } from '@/constants/routes'
import { IConfiguration, IModal } from '@/interfaces'
import { formatCurrency } from '@/functions/currency'
import { EModal } from '@/enums'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import PageHeaderNav from '@/components/navigations/page-header-nav/PageHeaderNav.vue'
import BConfigurationPreview from '@/components/buttons/BConfigurationPreview.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader,
    PageHeaderNav,
    BConfigurationPreview,
    BPrimary
  }
})

@Component
export default class Configuration extends Extender {
  mySAndLRoutes = mySAndLRoutes
  sellingPrice: null | string = null

  created() {
    const id = this.$route.params.configurationId

    /* Fetch configuration */
    api.Configuration.get(id)
      .then(res => {
        this.$store.dispatch('Configurations/setConfiguration', res.data)
        return res.data
      })
      .then((res: IConfiguration) => {
        api.Configuration.getSpecifications(res.id as string).then(specs => {
          this.$store.dispatch('Configurations/setSpecifications', specs.data)
        })
      })

    /* Fetch price */
    api.Configuration.getSellingPrice(id).then(res => {
      this.sellingPrice = formatCurrency(res.data.purchasePrice)
    })
  }

  beforeDestroy() {
    this.$store.dispatch('Configurations/setConfiguration', null)
  }

  openOrderConfirm() {
    const modal: IModal = {
      type: EModal.ORDER_S_AND_L_CONFIRM,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Modals/setModal', modal)
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }

  get specifications() {
    return this.$store.state.Configurations.specifications
  }

  get canOrder() {
    return (
      this.configuration.isComponentInfoOkay &&
      this.configuration.isSpecInfoOkay
    )
  }

  get isOrdered() {
    return this.configuration.orderedAt !== null
  }
}
