import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { IResource } from '@/interfaces'

@Module({ namespaced: true })
export class Catalogs extends VuexModule {
  catalogs: IResource | [] = []

  /* === MUTATIONS === */
  @Mutation
  SET_CATALOGS(catalogs: IResource | []) {
    this.catalogs = catalogs
  }
  /* ================= */

  /* === ACTIONS === */
  @Action
  setCatalogs(catalogs: IResource | []) {
    this.context.commit('SET_CATALOGS', catalogs)
  }
  /* =============== */
}
