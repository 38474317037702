

























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { IChapter, IModal } from '@/interfaces'
import { EModal } from '@/enums'
import { EChapter } from '@/constants/emptyObjects'
import { required } from 'vuelidate/lib/validators'
import BIcon from '@/components/buttons/BIcon.vue'
import RoundIcon from '@/components/RoundIcon.vue'
import PDFUpload from '@/components/PDFUpload.vue'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import Tags from '@/components/inputs/Tags.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import cloneDeep from 'lodash.clonedeep'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    BIcon,
    RoundIcon,
    PDFUpload,
    LabelOutInput,
    Tags,
    BPrimary
  },
  props: {
    chapter: {
      type: Object as () => IChapter
    },
    published: {
      type: Boolean,
      required: true
    }
  },
  validations: {
    chapterClone: {
      title: { required },
      keywords: { required }
    }
  }
})

@Component
export default class ResourceChapterItem extends Extender {
  edit = false
  uploadUrl = ''
  chapterClone: IChapter = cloneDeep(EChapter)

  openPreviewModal() {
    const modal: IModal = {
      type: EModal.PREVIEW_MODAL,
      edit: false,
      closable: false,
      pdfUrl: this.chapter.sourceFileUrl,
      title: this.chapter.title
    }

    this.$store.dispatch('Modals/setModal', modal)
  }

  created() {
    this.setChapter()
    this.uploadUrl = `${process.env.VUE_APP_API_URL}/chapter/${this.chapter.id}/upload`
  }

  setChapter() {
    this.chapterClone = cloneDeep(this.chapter)
  }

  onDelete() {
    const modal: IModal = {
      type: EModal.DELETE_RESOURCE_CHAPTER_CONFIRM,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Resources/setSelectedChapter', this.chapter)
    this.$store.dispatch('Modals/setModal', modal)
  }

  updateChapter() {
    const resourceId = this.$route.params.resourceId

    this.$v.chapterClone.$touch()

    if (this.$v.chapterClone.$invalid) return

    if (this.chapterClone !== null) {
      api.Chapter.update(this.chapterClone).then(() => {
        api.Resource.get(resourceId).then(res => {
          this.$store.dispatch('Resources/setSelectedResource', res.data)
          this.edit = false
        })
      })
    }
  }

  @Watch('chapter')
  watchChapterChange() {
    this.setChapter()
  }
}
