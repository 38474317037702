














































import { Component, Vue } from 'vue-property-decorator'
import { ISearch } from '@/interfaces'
import SearchBook from '@/components/search/SearchBook.vue'
import SearchFilters from '@/components/search/SearchFilters.vue'
import vClickOutside from 'v-click-outside'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    SearchBookResults: SearchBook,
    SearchFilters,
    MoonLoader
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
})

@Component
export default class CatalogSearch extends Extender {
  searchValue = ''
  error = ''
  results: Array<ISearch> = []
  showResults = false
  loading = false
  allCatalogs = []

  created() {
    this.searchValue = this.activeSearch || ''
    api.Catalog.getAll({
      searchValue: this.searchValue,
      category: 'ALL',
      sort: this.$store.state.Search.sort
    }).then(res => {
      this.$store.dispatch('Catalogs/setCatalogs', res.data)
      this.allCatalogs = res.data
    })
  }

  get amountChapters() {
    let amount = 0
    for (let i = 0; i < this.results.length; i++) {
      amount += this.results[i].chapters.length
    }
    return amount
  }

  get amountBooks() {
    return this.results.length
  }

  get selectedFilterValue() {
    return this.$store.state.Search.selectedFilterValue
  }

  get activeSearch() {
    return this.$store.state.Search.activeSearch
  }

  get catalogs() {
    return this.$store.state.Catalogs.catalogs
  }

  onInput(value: string) {
    if (value.length > 2) {
      this.loading = true
      this.$store.dispatch('Search/setActiveSearch', this.searchValue)

      api.Search.post({
        searchValue: value,
        category: this.selectedFilterValue,
        sort: this.$store.state.Search.sort
      })
        .then(res => {
          this.results = res.data
          this.showResults = true
          this.$store.dispatch('Catalogs/setCatalogs', this.results)
        })
        .catch(() => {
          this.error = this.$i18n.t('error_search_catalog') as string
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.showResults = false
      this.results = []

      this.$store.dispatch('Catalogs/setCatalogs', this.allCatalogs)
    }

    this.$store.dispatch('Search/setActiveSearch', this.searchValue)
  }

  onFocus() {
    this.showResults = !!this.amountBooks
    if (!this.results.length) {
      this.onInput(this.searchValue)
    }
  }

  onClickOutside() {
    this.showResults = false
  }
}
