import Catalogs from '@/views/catalog/Catalogs.vue'
import CatalogDetail from '@/views/catalog/CatalogDetail.vue'
import CatalogInformation from '@/views/catalog/CatalogInformation.vue'
import CatalogChapters from '@/views/catalog/CatalogChapters.vue'
import CatalogSelectedChapters from '@/views/catalog/CatalogSelectedChapters.vue'
import { catalogRoutes } from '@/constants/routes'

export const catalog = [
  {
    path: '/catalog',
    component: Catalogs,
    name: catalogRoutes.catalogOverview,
    meta: {
      requiresTeach: true,
      composer: {
        alwaysShow: true,
        onlyWhenSelection: true
      }
    }
  },
  {
    path: '/catalog/selection',
    component: CatalogSelectedChapters,
    name: catalogRoutes.catalogSelection,
    meta: {
      requiresTeach: true,
      composer: {
        alwaysShow: false,
        onlyWhenSelection: false
      }
    }
  },
  {
    path: '/catalog/:catalogId',
    component: CatalogDetail,
    children: [
      {
        path: '',
        name: catalogRoutes.catalogInformation,
        component: CatalogInformation,
        meta: {
          requiresTeach: true,
          composer: {
            alwaysShow: true,
            onlyWhenSelection: true
          }
        }
      },
      {
        path: 'chapters',
        name: catalogRoutes.catalogChapters,
        component: CatalogChapters,
        meta: {
          requiresTeach: true,
          composer: {
            alwaysShow: true,
            onlyWhenSelection: true
          }
        }
      }
    ]
  }
]
