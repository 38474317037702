




























































































































import { Component, Vue } from 'vue-property-decorator'
import { mySAndLRoutes } from '@/constants/routes'
import { finishes, printModes } from '@/constants/specifications'
import { TranslateResult } from 'vue-i18n'
import { ISpecification } from '@/interfaces'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import FormWithCover from '@/components/FormWithCover.vue'
import SmallInfo from '@/components/text-elements/SmallInfo.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import Dropdown from '@/components/inputs/Dropdown.vue'
import cloneDeep from 'lodash.clonedeep'
import * as api from '@/api'

type TSpecs = Array<{ value: string; label: TranslateResult }>

const Extender = Vue.extend({
  components: {
    LabelOutInput,
    FormWithCover,
    SmallInfo,
    BPrimary,
    Dropdown
  }
})

@Component
export default class ConfigurationSpecifications extends Extender {
  loading = false
  finishes: TSpecs = []
  printModes: TSpecs = []
  specsFetched = false

  formData: ISpecification = {
    printTitle: '',
    finish: '',
    printMode: '',
    estimatedNumberOfStudents: ''
  }

  created() {
    this.finishes = cloneDeep(finishes)
    this.printModes = cloneDeep(printModes)

    if (!this.isOrdered) {
      this.printModes = this.printModes.filter(
        printMode => printMode.value !== 'RECTO'
      )
    }

    const configurationId = this.configuration ? this.configuration.id : ''
    const routeId = this.$route.params.configurationId

    if (this.configuration && configurationId === routeId) {
      this.fetchSpecifications(this.configuration.id)
      return
    } else {
      this.fetchConfiguration()
    }
  }

  /* Routings */
  toChapters() {
    this.$router.push({ name: mySAndLRoutes.configurationChapters })
  }

  toOrder() {
    this.$router.push({ name: mySAndLRoutes.configurationOrder })
  }
  /* ===== */

  fetchConfiguration() {
    api.Configuration.get(this.$route.params.configurationId).then(res => {
      this.fetchSpecifications(res.data.id)
      this.$store.dispatch('Configurations/setConfiguration', res.data)
    })
  }

  fetchSpecifications(id: string) {
    api.Configuration.getSpecifications(id).then(res => {
      this.formData = cloneDeep(res.data)
      this.$store.dispatch('Configurations/setSpecifications', res.data)
    })
  }

  formSubmit() {
    this.loading = true
    this.formData.printMode = 'RECTO_VERSO'

    api.Configuration.postSpecifications(this.configuration.id, this.formData)
      .then(() => {
        this.$toasted.show(
          this.$i18n.t('posting_specifications_success') as string,
          {
            type: 'success'
          }
        )

        this.fetchConfiguration()
      })
      .catch(() => {
        this.$toasted.show(
          this.$i18n.t('error_posting_specifications') as string
        )
      })
      .finally(() => {
        this.loading = false
      })
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }

  get isOrdered() {
    return this.configuration.orderedAt !== null
  }
}
