
















































import { Component, Vue } from 'vue-property-decorator'
import BPrimary from '@/components/buttons/BPrimary.vue'
import RoundIcon from '@/components/RoundIcon.vue'
import NeedHelp from '@/components/labels/NeedHelp.vue'

const Extender = Vue.extend({
  components: {
    BPrimary,
    RoundIcon,
    NeedHelp
  }
})

@Component
export default class CatalogSelectionFail extends Extender {
  get violations() {
    return this.$store.state.NewConfiguration.violations
  }
}
