export const authorsToArr = (authors: string): Array<string> => {
  return authors
    .split(',')
    .map(author => author.trim())
    .filter(author => author !== '')
}

export const authorsToString = (authors: Array<string>): string => {
  return authors.join(', ')
}
