






import { Component, Vue } from 'vue-property-decorator'

const Extender = Vue.extend({
  props: {
    color: {
      type: String,
      required: true,
      validator: val =>
        ['primary', 'basketball', 'grass', 'danger'].indexOf(val) !== -1
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: val => ['xsm', 'sm', 'md'].indexOf(val) !== -1
    }
  }
})

@Component
export default class RoundIcon extends Extender {}
