import { render, staticRenderFns } from "./UserRegistered.vue?vue&type=template&id=6d7b1738&scoped=true&"
import script from "./UserRegistered.vue?vue&type=script&lang=ts&"
export * from "./UserRegistered.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d7b1738",
  null
  
)

export default component.exports