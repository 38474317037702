










import { Component, Vue } from 'vue-property-decorator'

const Extender = Vue.extend({
  props: {
    checked: {
      type: Boolean,
      required: true
    }
  }
})

@Component
export default class Checkbox extends Extender {}
