












import { Component, Vue } from 'vue-property-decorator'

const Props = Vue.extend({
  props: {
    title: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: [String, Number]
    },
    size: {
      required: true,
      type: String,
      validator: val => ['copy', 'body-1', 'subtitle'].indexOf(val) !== -1
    },
    sameLine: {
      type: Boolean,
      required: false,
      default: false
    },
    renderHTML: {
      type: Boolean,
      default: false
    }
  }
})

@Component
export default class SmallInfo extends Props {
  nl2br(value: string) {
    return (value + '').replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      '$1' + '<br />' + '$2'
    )
  }
}
