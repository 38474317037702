



























import { Component, Vue } from 'vue-property-decorator'
import { IModal } from '@/interfaces'
import { EModal } from '@/enums'
import BPrimary from '@/components/buttons/BPrimary.vue'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

const Extender = Vue.extend({
  components: {
    BPrimary,
    MoonLoader
  },
  props: {
    price: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})

@Component
export default class ConfigurationPrice extends Extender {
  openOrderConfirm() {
    const modal: IModal = {
      type: EModal.ORDER_S_AND_L_CONFIRM,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Modals/setModal', modal)
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }

  get isOrdered() {
    return this.configuration.orderedAt !== null
  }
}
