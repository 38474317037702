
























import { Component, Vue } from 'vue-property-decorator'
import ConfirmButtons from '@/components/buttons/ConfirmButtons.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    ConfirmButtons
  }
})

@Component
export default class DeleteConfigurationConfirm extends Extender {
  error = ''
  loading = false

  onDelete() {
    this.loading = true

    api.Configuration.delete(this.configuration.id)
      .then(() => {
        api.Configuration.getAll().then(res => {
          this.$store.dispatch('Configurations/setConfigurations', res.data)
          this.$store.dispatch('Modals/setModal', null)
        })
      })
      .catch(() => {
        this.error = this.$i18n.t('error_delete_configuration') as string
      })
      .finally(() => {
        this.loading = false
      })
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }
}
