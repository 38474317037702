





































import { Component, Vue } from 'vue-property-decorator'
import {
  mySAndLRoutes,
  catalogRoutes,
  adminRoutes,
  helpcenterRouters
} from '@/constants/routes'
import Menu from './utils/Menu.vue'
import Logo from './utils/Logo.vue'
import Link from './utils/Link.vue'

const Extender = Vue.extend({
  components: {
    Menu,
    Logo,
    Link
  }
})

@Component
export default class SideBar extends Extender {
  mySAndLRoutes = mySAndLRoutes
  catalogRoutes = catalogRoutes
  adminRoutes = adminRoutes
  helpcenterRouters = helpcenterRouters

  get isAdmin() {
    return this.$auth.user.profile.roles.indexOf('ROLE_ADMIN') !== -1
  }
  get isTeacher() {
    return (
      this.$auth.user.profile.roles.indexOf('ROLE_TEACHER') !== -1 ||
      this.$auth.user.profile.roles.indexOf('ROLE_ADMIN') !== -1
    )
  }

  get currentPage() {
    return this.$route.name
  }
}
