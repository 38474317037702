















































































import { Component, Vue } from 'vue-property-decorator'
import { IUserComponent } from '@/interfaces'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import PDFUpload from '@/components/PDFUpload.vue'
import RoundIcon from '@/components/RoundIcon.vue'
import PDF from '@/components/PDF.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    LabelOutInput,
    BPrimary,
    PDFUpload,
    RoundIcon,
    PDF
  }
})

@Component
export default class AddOwnContentToSAndL extends Extender {
  error = ''
  loading = false
  pdf: File | null = null
  formData: IUserComponent = {
    title: ''
  }

  $refs!: {
    dropzoneParent: HTMLFormElement
  }

  pdfAdded(pdf: File) {
    this.pdf = pdf
  }

  formSubmit() {
    const uploader = this.$refs.dropzoneParent.$refs.pdfUpload

    if (this.formData.title.trim() === '') return

    this.loading = true

    api.Configuration.postUserComponent(this.configuration.id, this.formData)
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .then((res: any) => {
        if (this.pdf) {
          uploader.setOption(
            'url',
            `${process.env.VUE_APP_API_URL}/configuration/${res.data.configuration}/user-content-component/${res.data.componentId}/upload`
          )

          uploader.processQueue()
        } else {
          this.fetchAndCloseModal()
        }
      })
      .catch(() => {
        this.error = this.$t('error_adding_own_content') as string
      })
  }

  async fetchAndCloseModal() {
    return api.Configuration.get(this.configuration.id)
      .then(res => {
        this.$store.dispatch('Configurations/setConfiguration', res.data)
        this.$store.dispatch('Modals/setModal', null)
      })
      .finally(() => {
        this.loading = false
      })
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }
}
