import i18n from '@/locales'

export const finishes = [
  {
    value: 'GLUED',
    label: i18n.t('glued')
  },
  {
    value: 'DRILLED',
    label: i18n.t('drilled')
  }
]

export const printModes = [
  {
    value: 'RECTO_VERSO',
    label: i18n.t('recto_verso')
  },
  {
    value: 'RECTO',
    label: i18n.t('recto')
  }
]
