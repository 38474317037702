



































import { Component, Vue } from 'vue-property-decorator'
import { IResource } from '@/interfaces'
import { catalogRoutes } from '@/constants/routes'
import Authors from '@/components/select-and-learns/utils/Authors.vue'
import ImagePlaceholder from '@/components/ImagePlaceholder.vue'

const Extender = Vue.extend({
  props: {
    resource: {
      type: Object as () => IResource
    }
  },
  components: {
    Authors,
    ImagePlaceholder
  }
})

@Component
export default class Catalog extends Extender {
  catalogRoutes = catalogRoutes
}
