



































import { Component, Vue } from 'vue-property-decorator'
import { mySAndLRoutes } from '@/constants/routes'
import BPrimary from '@/components/buttons/BPrimary.vue'
import RoundIcon from '@/components/RoundIcon.vue'

const Extender = Vue.extend({
  components: {
    BPrimary,
    RoundIcon
  }
})

@Component
export default class CatalogSelectionSuccess extends Extender {
  mySAndLRoutes = mySAndLRoutes

  get configuration() {
    return this.$store.state.Configurations.configuration
  }
}
