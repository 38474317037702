


































import { Component, Vue } from 'vue-property-decorator'

const Extender = Vue.extend({
  props: {
    backlink: {
      type: Object,
      required: false
    }
  }
})

@Component
export default class PageHeader extends Extender {}
