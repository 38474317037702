






































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/pages/PageHeader.vue'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import Catalog from '@/components/Catalog.vue'
import CatalogSearch from '@/components/CatalogSearch.vue'
import SubjectAreaFilter from '@/components/search/SubjectAreaFilter.vue'
import SearchSort from '@/components/search/SearchSort.vue'
import { IResource } from '@/interfaces'

const Extender = Vue.extend({
  components: {
    PageHeader,
    Catalog,
    TopNav,
    CatalogSearch,
    SubjectAreaFilter,
    SearchSort
  }
})

@Component
export default class CatalogOverview extends Extender {
  get catalogs() {
    if (!this.selectedSubjectAreaId) {
      return this.$store.state.Catalogs.catalogs
    }
    return (this.$store.state.Catalogs.catalogs as IResource[]).filter(
      catalog => {
        return catalog.subjectArea === this.selectedSubjectAreaId
      }
    )
  }

  get selectedSubjectAreaId() {
    return this.$store.state.Search.selectedSubjectAreaId
  }
}
