














import { Component, Vue } from 'vue-property-decorator'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

const Extender = Vue.extend({
  components: {
    MoonLoader
  },
  props: {
    full: {
      type: Boolean,
      required: false,
      default: false
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: val =>
        ['primary', 'greyed', 'white', 'tree'].indexOf(val) !== -1
    },
    iconColor: {
      type: String,
      required: false,
      default: 'icon-snow',
      validator: val =>
        ['icon-snow', 'icon-navy', 'icon-neon', 'icon-space'].indexOf(val) !==
        -1
    },
    size: {
      type: String,
      required: false,
      default: 'regular',
      validator: val => ['regular', 'lg'].indexOf(val) !== -1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: false
    }
  }
})

@Component
export default class BPrimary extends Extender {}
