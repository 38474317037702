
























import { Component, Vue } from 'vue-property-decorator'
import ConfirmButtons from '@/components/buttons/ConfirmButtons.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    ConfirmButtons
  }
})

@Component
export default class DeleteConfigurationComponentConfirm extends Extender {
  error = ''
  loading = false

  onDelete() {
    this.loading = true

    api.Configuration.deleteComponent(
      this.configuration.id,
      this.selectedComponent.id
    )
      .then(() => {
        return api.Configuration.get(this.configuration.id)
      })
      .then(res => {
        return this.$store.dispatch('Configurations/setConfiguration', res.data)
      })
      .then(() => {
        this.$store.dispatch('Modals/setModal', null)
        this.$toasted.show(
          this.$i18n.t('chapter_deleted_successfully') as string,
          {
            type: 'success'
          }
        )
      })
      .catch(() => {
        this.error = this.$t('delete_chapter_error') as string
      })
      .finally(() => {
        this.loading = false
      })
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }

  get selectedComponent() {
    return this.$store.state.Configurations.selectedComponent
  }
}
