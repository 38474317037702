






















import { Component, Vue } from 'vue-property-decorator'
import BIcon from '@/components/buttons/BIcon.vue'

const Extender = Vue.extend({
  components: {
    BIcon
  }
})

@Component
export default class AuthButton extends Extender {
  clearState() {
    localStorage.removeItem('REGISTER_MODAL_CHECKED')
  }
}
