

















import { Component, Vue } from 'vue-property-decorator'
import getVideoId from 'get-video-id'

const Extender = Vue.extend({
  props: {
    video: {
      type: Object,
      required: false
    }
  }
})

@Component
export default class Video extends Extender {
  get videoId() {
    return getVideoId(this.video.url).id
  }

  get videoImg() {
    return `https://img.youtube.com/vi/${this.videoId}/mqdefault.jpg`
  }
}
