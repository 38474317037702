





































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { IConfiguration, IInstitute } from '@/interfaces'
import { mySAndLRoutes } from '@/constants/routes'
import { required } from 'vuelidate/lib/validators'
import { authorsToString, authorsToArr } from '@/functions/authors'
import FormWithCover from '@/components/FormWithCover.vue'
import SmallInfo from '@/components/text-elements/SmallInfo.vue'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import Dropdown from '@/components/inputs/Dropdown.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    FormWithCover,
    SmallInfo,
    LabelOutInput,
    BPrimary,
    Dropdown
  },
  validations: {
    formData: {
      title: { required },
      institute: { required },
      department: { required },
      fieldOfStudy: { required },
      academicYear: { required },
      phase: { required }
    }
  }
})

@Component
export default class ConfigurationInformation extends Extender {
  loading = false
  institutes = []
  campuses = []
  coauthors = ''

  formData: IConfiguration = {
    id: '',
    title: '',
    author: '',
    authorText: '',
    coauthors: [],
    subTitle: '',
    coverImage: '',
    institute: '',
    campus: '',
    department: '',
    academicYear: '',
    fieldOfStudy: '',
    previewFileUrl: '',
    status: 'IN_DRAFT',
    phase: '',
    orderedAt: '',
    components: [],
    sellingPrice: 0,
    isComponentInfoOkay: false,
    isSpecInfoOkay: false,
    numberOfPages: 0
  }

  created() {
    this.formData = cloneDeep(this.configuration)
    this.coauthors = authorsToString(this.configuration.coauthors)

    api.Institute.get().then(res => {
      this.institutes = res.data
      this.prefillCampus()
    })
  }

  /* Routings */
  toChapters() {
    this.$router.push({ name: mySAndLRoutes.configurationChapters })
  }
  /* ===== */

  prefillCampus() {
    this.campuses = []
    this.formData.campus = ''

    /* eslint-disable-next-line */
    const institute: any = this.institutes.find(
      (institute: IInstitute) => institute.id === this.formData.institute
    )

    if (institute) {
      this.campuses = institute.campuses
    }
  }

  onFormSubmit() {
    this.$v.$touch()

    if (this.$v.$error) return

    this.formData.coauthors = authorsToArr(this.coauthors)

    this.loading = true
    if (this.formData && this.formData.id) {
      api.Configuration.update(this.formData.id, this.formData)
        .then(() => {
          return api.Configuration.get(this.$route.params.configurationId)
        })
        .then(res => {
          this.$store.dispatch('Configurations/setConfiguration', res.data)
          this.formData = cloneDeep(res.data)

          this.$toasted.show(
            this.$t('configuration_updated_succesfully') as string,
            {
              type: 'success'
            }
          )
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }

  get isSame() {
    return (
      isEqual(this.formData, this.configuration) &&
      authorsToString(this.configuration.coauthors) === this.coauthors
    )
  }

  get isOrdered() {
    return this.configuration.orderedAt !== null
  }

  @Watch('formData.institute', { deep: true })
  onInstituteChange() {
    this.prefillCampus()
  }
}
