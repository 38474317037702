

































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { EModal } from '@/enums'
import { IModal, IChapter, IConfigurationChapter } from '@/interfaces'
import { catalogRoutes, mySAndLRoutes } from '@/constants/routes'
import SAndLCreatedAddChapters from '@/components/SAndLCreatedAddChapters.vue'
import PageInfo from '@/components/pages/PageInfo.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import ChapterMoveItem from '@/components/ChapterMoveItem.vue'
import BIcon from '@/components/buttons/BIcon.vue'
import Draggable from 'vuedraggable'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    SAndLCreatedAddChapters,
    PageInfo,
    BPrimary,
    ChapterMoveItem,
    BIcon,
    Draggable
  }
})

@Component
export default class ConfigurationChapters extends Extender {
  catalogRoutes = catalogRoutes
  chapters: Array<IChapter> = []

  created() {
    this.setChapters(this.configuration.components)
  }

  /* Routings */
  toInformation() {
    this.$router.push({ name: mySAndLRoutes.configurationInformation })
  }

  toSpecifications() {
    this.$router.push({ name: mySAndLRoutes.configurationSpecifications })
  }
  /* ===== */

  setChapters(chapters: Array<IChapter>) {
    this.chapters = chapters
  }

  onOwnContent() {
    const modalToOpen: IModal = {
      type: EModal.ADD_OWN_CONTENT_TO_S_AND_L,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Modals/setModal', modalToOpen)
  }

  openPreviewModal(chapter: IConfigurationChapter) {
    const modal: IModal = {
      type: EModal.PREVIEW_MODAL,
      edit: false,
      closable: true,
      pdfUrl: chapter.fileUrl,
      title: chapter.title
    }

    this.$store.dispatch('Modals/setModal', modal)
  }

  deleteComponent(chapter: IChapter) {
    const modal: IModal = {
      type: EModal.DELETE_CONFIGURATION_COMPONENT,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Configurations/setSelectedComponent', chapter)
    this.$store.dispatch('Modals/setModal', modal)
  }

  listSorted() {
    const chapterIds = {
      orderedComponents: this.chapters.map((chapter: IChapter) => chapter.id)
    }

    api.Configuration.orderComponents(this.configuration.id, chapterIds)
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }

  get isOrdered() {
    return this.configuration.orderedAt !== null
  }

  @Watch('configuration')
  onConfigurationChange() {
    this.setChapters(this.configuration.components)
  }
}
