























import { Component, Vue } from 'vue-property-decorator'
import { mySAndLRoutes } from '@/constants/routes'
import ConfirmButtons from '@/components/buttons/ConfirmButtons.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    ConfirmButtons
  }
})

@Component
export default class OrderSAndLConfirm extends Extender {
  error = ''

  order() {
    api.Configuration.orderSAndL(this.configuration.id)
      .then(() => {
        this.$store.dispatch('Modals/setModal', null)
        this.$router.push({ name: mySAndLRoutes.myOrders })
      })
      .catch(() => {
        this.error = this.$t('order_s_and_l_failed') as string
      })
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }
}
