import i18n from '@/locales'

import { catalogRoutes, helpcenterRouters } from '../constants/routes'
import { adminRoutes } from '../constants/routes'
import { mySAndLRoutes } from '../constants/routes'

export const topnavs = {
  mySAndL: {
    title: i18n.t('my_s_and_l_full'),
    color: 'banana',
    links: [
      {
        to: { name: mySAndLRoutes.myConfigurations },
        label: i18n.t('my_select_and_learns')
      },
      {
        to: { name: mySAndLRoutes.myOrders },
        label: i18n.t('my_orders')
      }
    ]
  },
  catalog: {
    title: i18n.t('catalog'),
    color: 'basket-ball',
    links: [
      {
        to: { name: catalogRoutes.catalogOverview },
        label: i18n.t('catalog_overview')
      }
    ]
  },
  admin: {
    title: i18n.t('admin'),
    color: 'navy',
    links: [
      {
        to: { name: adminRoutes.resourcesOverview },
        label: i18n.t('resources')
      },
      {
        to: { name: adminRoutes.usersOverview },
        label: i18n.t('users')
      },
      {
        to: { name: adminRoutes.ordersOverview },
        label: i18n.t('orders')
      }
    ]
  },
  helpcenter: {
    title: i18n.t('helpcenter'),
    color: 'grass',
    links: [
      {
        to: { name: helpcenterRouters.videos },
        label: i18n.t('videos')
      },
      {
        to: { name: helpcenterRouters.faq },
        label: i18n.t('faq')
      }
    ]
  }
}
