



































import { Component, Vue } from 'vue-property-decorator'
import { adminRoutes } from '@/constants/routes'
import ConfirmButtons from '@/components/buttons/ConfirmButtons.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    ConfirmButtons
  }
})

@Component
export default class PublishResourceConfirm extends Extender {
  error = ''
  loading = false

  onPublish() {
    this.loading = true

    api.Resource.publish(this.resource.id)
      .then(() => {
        if (this.$route.name === adminRoutes.resourceInformation) {
          api.Resource.get(this.resource.id).then(res => {
            this.$store.dispatch('Resources/setSelectedResource', res.data)
            this.$store.dispatch('Modals/setModal', null)
          })
        }

        if (this.$route.name === adminRoutes.resourcesOverview) {
          api.Resource.getAll().then(res => {
            this.$store.dispatch('Resources/setResources', res)
            this.$store.dispatch('Modals/setModal', null)
          })
        }

        this.$toasted.show(
          this.$i18n.t('published_resource_success') as string,
          {
            type: 'success'
          }
        )
      })
      .catch(err => {
        this.error = err.response.data.message
      })
      .finally(() => {
        this.loading = false
      })
  }

  get resource() {
    return this.$store.state.Resources.selectedResource
  }
}
