




































































































































import { Component, Vue } from 'vue-property-decorator'
import { finishes, printModes } from '@/constants/specifications'
import { formatCurrency } from '@/functions/currency'
import { authorsToString } from '@/functions/authors'
import FormWithCover from '@/components/FormWithCover.vue'
import SmallInfo from '@/components/text-elements/SmallInfo.vue'
import ConfigurationPrice from '@/components/ConfigurationPrice.vue'
import * as api from '@/api'
const Extender = Vue.extend({
  components: {
    FormWithCover,
    SmallInfo,
    ConfigurationPrice
  }
})

@Component
export default class ConfigurationOrder extends Extender {
  sellingPrice = '0'
  priceLoading = false
  authorsToString = authorsToString

  created() {
    this.priceLoading = true
    this.getPrice(this.$route.params.configurationId)
  }

  getPrice(id: string) {
    api.Configuration.getSellingPrice(id)
      .then(res => {
        this.sellingPrice = formatCurrency(res.data.purchasePrice)
      })
      .finally(() => {
        this.priceLoading = false
      })
  }

  get configuration() {
    return this.$store.state.Configurations.configuration
  }

  get finish() {
    const spec = finishes.find(el => el.value === this.specifications.finish)
    return spec ? spec.label : ''
  }

  get printMode() {
    const printMode = this.specifications.printMode
    const spec = printModes.find(el => el.value === printMode)
    return spec ? spec.label : ''
  }

  get specifications() {
    return this.$store.state.Configurations.specifications
  }
}
