import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { IConfiguration, IChapter, ISpecification } from '@/interfaces'

@Module({ namespaced: true })
export class Configurations extends VuexModule {
  configurations: Array<IConfiguration> = []
  orderedConfigurations: Array<IConfiguration> = []
  configuration: IConfiguration | null = null
  selectedComponent: IChapter | null = null
  specifications: ISpecification | null = null

  /* === MUTATIONS === */
  @Mutation
  SET_CONFIGURATIONS(configurations: Array<IConfiguration>) {
    this.configurations = configurations
  }
  @Mutation
  SET_ORDERED_CONFIGURATIONS(orderedConfigurations: Array<IConfiguration>) {
    this.orderedConfigurations = orderedConfigurations
  }
  @Mutation
  SET_CONFIGURATION(configuration: IConfiguration) {
    this.configuration = configuration
  }
  @Mutation
  SET_SELECTED_COMPONENT(chapter: IChapter) {
    this.selectedComponent = chapter
  }
  @Mutation
  SET_SPECIFICATIONS(specifications: ISpecification) {
    this.specifications = specifications
  }
  /* ================= */

  /* === ACTIONS === */
  @Action
  setConfigurations(configurations: Array<IConfiguration>) {
    this.context.commit('SET_CONFIGURATIONS', configurations)
  }
  @Action
  setOrderedConfigurations(orderedConfigurations: Array<IConfiguration>) {
    this.context.commit('SET_ORDERED_CONFIGURATIONS', orderedConfigurations)
  }
  @Action
  setConfiguration(configuration: IConfiguration | null) {
    this.context.commit('SET_CONFIGURATION', configuration)
  }
  @Action
  setSelectedComponent(chapter: IChapter) {
    this.context.commit('SET_SELECTED_COMPONENT', chapter)
  }
  @Action
  setSpecifications(specifications: ISpecification) {
    this.context.commit('SET_SPECIFICATIONS', specifications)
  }
  /* =============== */
}
