





















































import { Component, Vue } from 'vue-property-decorator'
import { catalogRoutes } from '@/constants/routes'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import PageHeaderNav from '@/components/navigations/page-header-nav/PageHeaderNav.vue'
import CatalogSearch from '@/components/CatalogSearch.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader,
    PageHeaderNav,
    CatalogSearch
  }
})

@Component
export default class Catalog extends Extender {
  catalogRoutes = catalogRoutes

  created() {
    api.Resource.get(this.$route.params.catalogId).then(res => {
      this.$store.dispatch('Resources/setSelectedResource', res.data)
    })
  }

  get resource() {
    return this.$store.state.Resources.selectedResource
  }
}
