












import { Component, Vue } from 'vue-property-decorator'

const Extender = Vue.extend({
  props: {
    formPadding: {
      type: Boolean,
      required: false,
      default: true
    }
  }
})

@Component
export default class FormWithCover extends Extender {}
