

































































































































































import { Component, Vue } from 'vue-property-decorator'
import { IResource } from '@/interfaces'
import { adminRoutes } from '@/constants/routes'
import { authorsToArr } from '@/functions/authors'
import { required, minLength, minValue } from 'vuelidate/lib/validators'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageHeader from '@/components/pages/PageHeader.vue'
import LabelOutInput from '@/components/inputs/LabelOutInput.vue'
import Currency from '@/components/inputs/Currency.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import CoverUploader from '@/components/CoverUploader.vue'
import Dropdown from '@/components/inputs/Dropdown.vue'
import Tags from '@/components/inputs/Tags.vue'
import FormWithCover from '@/components/FormWithCover.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    TopNav,
    PageHeader,
    LabelOutInput,
    Currency,
    BPrimary,
    CoverUploader,
    Dropdown,
    Tags,
    FormWithCover
  },
  validations: {
    authors: { required },
    formData: {
      title: { required },
      description: { required },
      isbn: { required },
      sellingPrice: { required, minValue: minValue(1) },
      keywords: { required, minLength: minLength(1) },
      subjectArea: { required },
      publisher: { required }
    }
  }
})

@Component
export default class CreateCatalogResource extends Extender {
  adminRoutes = adminRoutes

  loading = false
  imgAdded = false
  sellingPrice = ''
  authors = ''

  formData: IResource = {
    id: '',
    title: '',
    description: '',
    isbn: '',
    sellingPrice: 0,
    subjectArea: '',
    publisher: '',
    authors: [],
    keywords: [],
    subjectAreaName: '',
    coverImageUrl: '',
    publishedAt: '',
    chapters: [],
    colofonUrl: '',
    previousVersion: null,
    newVersion: null
  }

  $refs!: {
    dropzoneParentIMG: HTMLFormElement
  }

  created() {
    api.SubjectArea.getAll().then(res => {
      this.$store.dispatch('Resources/setSubjectAreas', res.data)
    })

    api.Publisher.getAll().then(res => {
      this.$store.dispatch('Resources/setPublishers', res.data)
    })
  }

  onFormSubmit() {
    this.$v.$touch()

    if (this.$v.$invalid) return

    const coverUploader = this.$refs.dropzoneParentIMG.$refs.coverUploader

    // Split authors string into array.
    if (this.authors) {
      this.formData.authors = authorsToArr(this.authors)
    }

    this.loading = true

    // Post catalog resource.
    api.Resource.post(this.formData).then(res => {
      // If user added an image, change img upload url and start uploading.
      if (this.imgAdded) {
        const coverUrl = `${process.env.VUE_APP_API_URL}/catalog-resource/${res.data.id}/upload-cover-image`

        coverUploader.setOption('url', coverUrl)
        coverUploader.processQueue()
      }
      // If user hasn't uploaded an image, post new resource and go back.
      else {
        this.fetchAndGoBack()
      }
    })
  }

  fetchAndGoBack() {
    api.Resource.getAll()
      .then(res => {
        this.$store.dispatch('Resources/setResources', res)
        this.$router.push({ name: adminRoutes.resourcesOverview })
      })
      .finally(() => {
        this.loading = false
      })
  }

  get subjectAreas() {
    return this.$store.state.Resources.subjectAreas
  }

  get publishers() {
    return this.$store.state.Resources.publishers
  }
}
