






















































































import { Component, Vue } from 'vue-property-decorator'
import { mySAndLRoutes } from '@/constants/routes'
import { IConfiguration, IModal } from '@/interfaces'
import { EModal } from '@/enums'
import Label from '@/components/labels/Label.vue'
import BIcon from '@/components/buttons/BIcon.vue'
import SmallInfo from '@/components/text-elements/SmallInfo.vue'
import Authors from '@/components/select-and-learns/utils/Authors.vue'

const Extender = Vue.extend({
  components: {
    Label,
    BIcon,
    SmallInfo,
    Authors
  },
  props: {
    configuration: {
      type: Object as () => IConfiguration
    }
  }
})

@Component
export default class SAndLGrid extends Extender {
  mySAndLRoutes = mySAndLRoutes

  deleteConfiguration() {
    const modal: IModal = {
      type: EModal.DELETE_CONFIGURATION_CONFIRM,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Configurations/setConfiguration', this.configuration)
    this.$store.dispatch('Modals/setModal', modal)
  }

  openPDF() {
    const modal: IModal = {
      type: EModal.PREVIEW_MODAL,
      edit: false,
      closable: false,
      pdfUrl: this.configuration.previewFileUrl,
      title: this.configuration.title
    }

    this.$store.dispatch('Modals/setModal', modal)
  }

  get authors() {
    const authors = []

    if (this.configuration.authorText) {
      authors.push(this.configuration.authorText)
    }

    if (this.configuration.coauthors.length > 0) {
      authors.push(...this.configuration.coauthors)
    }

    return authors
  }
}
