
























import { Component, Vue } from 'vue-property-decorator'
import ConfirmButtons from '@/components/buttons/ConfirmButtons.vue'
import * as api from '@/api'
import { adminRoutes } from '@/constants/routes'

const Extender = Vue.extend({
  components: {
    ConfirmButtons
  }
})

@Component
export default class ReviseResourceConfirm extends Extender {
  error = ''
  loading = false

  onRevise() {
    this.loading = true

    api.Resource.revise(this.resource.id)
      .then(() => {
        if (this.$route.name === adminRoutes.resourceInformation) {
          this.$store.dispatch('Modals/setModal', null)
        }

        if (this.$route.name === adminRoutes.resourcesOverview) {
          api.Resource.getAll().then(res => {
            this.$store.dispatch('Resources/setResources', res)
            this.$store.dispatch('Modals/setModal', null)
          })
        }

        this.$toasted.show(this.$i18n.t('revised_resource_success') as string, {
          type: 'success'
        })
      })
      .catch(() => {
        this.error = this.$i18n.t('error_revise_resource') as string
      })
      .finally(() => {
        this.loading = false
      })
  }

  get resource() {
    return this.$store.state.Resources.selectedResource
  }
}
