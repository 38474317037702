import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
export class Videos extends VuexModule {
  selectedVideo = null
  videos = [
    {
      title: 'Studiemateriaal 100% op maat',
      duration: '02:46',
      url: 'https://www.youtube.com/watch?v=bqUn4O9Mgss'
    },
    {
      title: 'Registeren bij Select & Learn',
      duration: '00:45',
      url: 'https://youtu.be/MsFVR6PjKWY'
    },
    {
      title: 'Je Select & Learn aanmaken',
      duration: '00:54',
      url: 'https://youtu.be/jvaabKH8AfA'
    },
    {
      title: 'Hoofdstukken toevoegen aan je Select & Learn',
      duration: '01:38',
      url: 'https://youtu.be/v_iEc8SgOSY'
    },
    {
      title: 'Eigen inhoud toevoegen aan je Select & Learn',
      duration: '00:49',
      url: 'https://youtu.be/zNClYH9RKyI'
    }
  ]

  /* === MUTATIONS === */
  @Mutation
  SET_SELECTED_VIDEO(video: null) {
    this.selectedVideo = video
  }
  /* ================= */

  /* === ACTIONS === */
  @Action
  setSelectedVideo(video: null) {
    this.context.commit('SET_SELECTED_VIDEO', video)
  }
  /* =============== */
}
