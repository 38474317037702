


















import { Component, Vue, Watch } from 'vue-property-decorator'
import { IChapter } from '@/interfaces'
import ChapterSelect from '@/components/chapters/ChapterSelect.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    ChapterSelect
  }
})

@Component
export default class ConfigurationChapters extends Extender {
  @Watch('$route', { immediate: true, deep: true })
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onUrlChange(route: any) {
    if (route.params.catalogId) {
      this.getSelectedResources()
    }
  }

  getSelectedResources() {
    api.Resource.get(this.$route.params.catalogId).then(res => {
      this.$store
        .dispatch('Resources/setSelectedResource', res.data)
        .then(() => {
          this.addChapterIdFromParams()
        })
    })
  }

  checkIfSelected(chapter: IChapter) {
    const founded = this.selectedChapters.find(
      (el: IChapter) => el.id === chapter.id
    )

    return !!founded
  }

  addChapter(chapter: IChapter) {
    if (!this.checkIfSelected(chapter)) {
      this.$store.dispatch('NewConfiguration/addChapter', chapter)
    } else {
      this.$store.dispatch('NewConfiguration/removeChapter', chapter)
    }
  }

  addChapterIdFromParams() {
    if (this.$route.query.chapterId) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const userAddedChapter: any = this.getChapterById()
      this.addChapter(userAddedChapter)
    }
  }

  getChapterById() {
    let formattedChapter = null

    if (this.selectedResource.chapters.length) {
      formattedChapter = this.selectedResource.chapters.find(
        (item: IChapter) => item.id === this.$route.query.chapterId
      ) as IChapter
    }

    return formattedChapter
  }

  get selectedResource() {
    return this.$store.state.Resources.selectedResource
  }

  get selectedChapters() {
    return this.$store.state.NewConfiguration.selectedChapters
  }
}
