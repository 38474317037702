










import { Component, Vue } from 'vue-property-decorator'
import SearchBookItem from '@/components/search/SearchBookItem.vue'

const Extender = Vue.extend({
  props: {
    data: {
      type: Array
    }
  },
  components: {
    SearchBookItem
  }
})

@Component
export default class SearchBook extends Extender {}
