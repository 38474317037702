
























































































































import { Component, Vue } from 'vue-property-decorator'
import { catalogRoutes } from '@/constants/routes'
import { EModal } from '@/enums'
import { IModal, IChapter } from '@/interfaces'
import { required } from 'vuelidate/lib/validators'
import { mySAndLRoutes } from '@/constants/routes'
import PageHeader from '@/components/pages/PageHeader.vue'
import TopNav from '@/components/navigations/top-nav/TopNav.vue'
import PageInfo from '@/components/pages/PageInfo.vue'
import ChapterMoveItem from '@/components/ChapterMoveItem.vue'
import BIcon from '@/components/buttons/BIcon.vue'
import Dropdown from '@/components/inputs/Dropdown.vue'
import BPrimary from '@/components/buttons/BPrimary.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    PageHeader,
    TopNav,
    PageInfo,
    ChapterMoveItem,
    BIcon,
    Dropdown,
    BPrimary
  },
  validations: {
    selectedConfiguration: { required }
  }
})

@Component
export default class CatalogSelectedChapters extends Extender {
  mySAndLRoutes = mySAndLRoutes
  catalogRoutes = catalogRoutes
  selectedConfiguration = ''

  async created() {
    await api.Configuration.getAll().then(res => {
      this.$store.dispatch('Configurations/setConfigurations', res.data)
    })
  }

  beforeDestroy() {
    this.$store.dispatch('NewConfiguration/setViolations', [])
  }

  confirm() {
    this.$v.$touch()

    if (this.$v.$invalid) return

    const { selectedConfiguration, chapterIds } = this

    api.Configuration.postChapterComponent(selectedConfiguration, chapterIds)
      .then(() => {
        return api.Configuration.get(selectedConfiguration)
      })
      .then(res => {
        const modalToOpen: IModal = {
          type: EModal.CATALOG_SELECTION_SUCCESS,
          edit: false,
          closable: false
        }

        this.$store.dispatch('Configurations/setConfiguration', res.data)
        this.$store.dispatch('NewConfiguration/setSelectedChapters', [])
        this.$store.dispatch('NewConfiguration/setViolations', [])
        this.$store.dispatch('Modals/setModal', modalToOpen)
      })
      .catch(err => {
        const modalToOpen: IModal = {
          type: EModal.CATALOG_SELECTION_FAIL,
          edit: false,
          closable: true
        }

        this.$store.dispatch(
          'NewConfiguration/setViolations',
          err.response.data.violations
        )

        this.$store.dispatch('Modals/setModal', modalToOpen)
      })
  }

  openPreview(chapter: IChapter) {
    const modal: IModal = {
      type: EModal.PREVIEW_MODAL,
      edit: false,
      closable: false,
      pdfUrl: chapter.previewFileUrl,
      title: chapter.title
    }

    this.$store.dispatch('Modals/setModal', modal)
  }

  deleteChapter(chapter: IChapter) {
    this.$store.dispatch('NewConfiguration/removeChapter', chapter)
  }

  openModal(type: string) {
    const modalToOpen: IModal = {
      type: EModal[type],
      edit: false,
      closable: true
    }

    this.$store.dispatch('Modals/setModal', modalToOpen)
  }

  get configurations() {
    return this.$store.state.Configurations.configurations
  }

  get chapterAmount(): number {
    return this.selectedChapters.length
  }

  get bookAmount(): number {
    return [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...new Set(this.selectedChapters.map((data: any) => data.catalogResource))
    ].length
  }

  get selectedChapters() {
    return this.$store.state.NewConfiguration.selectedChapters
  }

  get chapterIds() {
    return this.selectedChapters.map((chapter: IChapter) => chapter.id)
  }

  get violationList() {
    let liItems = ``

    for (const violation of this.violations) {
      liItems += `<li>${violation.message}</li>`
    }

    return liItems
  }

  get violations() {
    return this.$store.state.NewConfiguration.violations
  }
}
