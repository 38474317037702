























































































































import { Component, Vue } from 'vue-property-decorator'
import { IResource, IModal } from '@/interfaces'
import { EModal } from '@/enums'
import { adminRoutes } from '@/constants/routes'
import ImagePlaceholder from '@/components/ImagePlaceholder.vue'
import BIcon from '@/components/buttons/BIcon.vue'

const Extender = Vue.extend({
  components: {
    BIcon,
    ImagePlaceholder
  },
  props: {
    resource: {
      type: Object as () => IResource,
      required: true
    }
  }
})

@Component
export default class Resource extends Extender {
  adminRoutes = adminRoutes

  onPublish() {
    const modalToOpen: IModal = {
      type: EModal.PUBLISH_RESOURCE_CONFIRM,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Resources/setSelectedResource', this.resource)
    this.$store.dispatch('Modals/setModal', modalToOpen)
  }

  onDelete() {
    const modalToOpen: IModal = {
      type: EModal.DELETE_RESOURCE_CONFIRM,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Resources/setSelectedResource', this.resource)
    this.$store.dispatch('Modals/setModal', modalToOpen)
  }

  onRevise() {
    const modalToOpen: IModal = {
      type: EModal.REVISE_RESOURCE_CONFIRM,
      edit: false,
      closable: true
    }

    this.$store.dispatch('Resources/setSelectedResource', this.resource)
    this.$store.dispatch('Modals/setModal', modalToOpen)
  }
}
