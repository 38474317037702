/* eslint-disable @typescript-eslint/camelcase */
import { UserManager, WebStorageStateStore, User } from 'oidc-client'

export default class AuthService {
  private userManager: UserManager

  constructor() {
    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: process.env.VUE_APP_AUTH_AUTHORITY_SERVER,
      client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid profile',
      post_logout_redirect_uri: window.location.origin,
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: true,
      response_mode: 'query'
    }

    this.userManager = new UserManager(settings)
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser()
  }

  public async getAccessToken(): Promise<string | null> {
    const user: User | null = await this.getUser()

    if (user !== null && !user.expired) {
      return user.access_token
    } else {
      return null
    }
  }

  public loginSilent(): void {
    this.userManager.signinSilent()
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect()
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect()
  }

  public async isLoggedIn(): Promise<boolean> {
    const user: User | null = await this.getUser()

    return user !== null && !user.expired
  }

  public handleCallback(): Promise<User> {
    return this.userManager.signinRedirectCallback()
  }

  public getUserManager(): UserManager {
    return this.userManager
  }
}
