import Vue from 'vue'
import AuthService from '@/services/AuthService'
import { User, Profile } from 'oidc-client'
import * as api from '@/api'
export default {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  install: (vue: any, authService: AuthService) => {
    vue.prototype.$auth = new Vue({
      data() {
        const profile: Profile = {
          iss: '',
          sub: '',
          aud: '',
          exp: 0,
          iat: 0
        }
        /* eslint-disable @typescript-eslint/camelcase */
        const user: User = {
          id_token: '',
          access_token: '',
          token_type: '',
          scope: '',
          profile,
          expires_at: 0,
          state: '',
          toStorageString: () => '',
          expires_in: 0,
          expired: false,
          scopes: []
        }
        return {
          user
        }
      },
      methods: {
        logout: () => {
          authService.logout()
        },
        getRoles() {
          authService.getAccessToken().then(token => {
            if (token !== null) {
              api.Usercontext.get(token as string).then(res => {
                this.user.profile.roles = res.data.roles
              })
            }
          })
        }
      },
      async created() {
        this.user = (await authService.getUser()) as User
        this.getRoles()

        authService
          .getUserManager()
          .events.addUserLoaded((user: User): void => {
            this.user = user
            this.getRoles()
          })

        authService.getUserManager().events.addAccessTokenExpired(
          async (): Promise<void> => {
            await authService
              .getUserManager()
              .signinSilent()
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              .catch((reason: any) => {
                console.error(reason)
                authService.getUserManager().signoutRedirect()
              })
          }
        )
      }
    })
  }
}
