













import { Component, Vue } from 'vue-property-decorator'
import { catalogRoutes } from '@/constants/routes'

const Extender = Vue.extend({
  props: {
    item: {
      type: Object
    }
  }
})

@Component
export default class SearchChapterItem extends Extender {
  catalogRoutes = catalogRoutes

  setParams() {
    this.$router
      .push({
        name: this.catalogRoutes.catalogChapters,
        params: {
          catalogId: this.item.catalogResource
        },
        query: {
          chapterId: this.item.id
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((): void => {})
  }
}
