import { render, staticRenderFns } from "./FormWithCover.vue?vue&type=template&id=1f26699c&scoped=true&"
import script from "./FormWithCover.vue?vue&type=script&lang=ts&"
export * from "./FormWithCover.vue?vue&type=script&lang=ts&"
import style0 from "./FormWithCover.vue?vue&type=style&index=0&id=1f26699c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f26699c",
  null
  
)

export default component.exports