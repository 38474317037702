










import { Component, Vue } from 'vue-property-decorator'

const Props = Vue.extend({
  props: {
    boxShadow: {
      type: Boolean,
      required: false,
      default: true
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: val =>
        ['primary', 'danger', 'neon', 'none', 'disabled'].indexOf(val) !== -1
    },
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: val => ['sm', 'md'].indexOf(val) !== -1
    }
  }
})

@Component
export default class BIcon extends Props {}
