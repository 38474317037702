



















import { Component, Vue } from 'vue-property-decorator'
import BIcon from '@/components/buttons/BIcon.vue'

const Extender = Vue.extend({
  components: {
    BIcon
  },
  props: {
    pdf: {
      type: File,
      required: false
    }
  }
})

@Component
export default class PDF extends Extender {}
