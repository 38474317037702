import Videos from '@/views/helpcenter/Videos.vue'
import FAQ from '@/views/helpcenter/FAQ.vue'
import { helpcenterRouters } from '@/constants/routes'

export const helpcenter = [
  {
    path: '/videos',
    component: Videos,
    name: helpcenterRouters.videos,
    meta: {
      composer: {
        alwaysShow: false,
        onlyWhenSelection: true
      }
    }
  },
  {
    path: '/veelgestelde-vragen',
    component: FAQ,
    name: helpcenterRouters.faq,
    meta: {
      composer: {
        alwaysShow: false,
        onlyWhenSelection: true
      }
    }
  }
]
