





























import { Component, Vue } from 'vue-property-decorator'
import { IOrder } from '@/interfaces'
import { formatCurrency } from '@/functions/currency'
import BPrimary from '@/components/buttons/BPrimary.vue'
import * as api from '@/api'

const Extender = Vue.extend({
  components: {
    BPrimary
  },
  props: {
    order: {
      type: Object as () => IOrder,
      required: true
    }
  }
})

@Component
export default class Order extends Extender {
  loading = false

  get sellingPrice() {
    return formatCurrency(this.order.sellingPrice)
  }

  download() {
    this.loading = true

    api.Asset.download(this.order.renderUrl).then(response => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', 'file.pdf')
      document.body.appendChild(fileLink)

      this.loading = false
      fileLink.click()
    })
  }
}
